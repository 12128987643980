import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";


import {
  getQuickAnalyticsSuccess
} from "../actions/analytics.action";

const getQuickAnalyticsData = async ( params ) => {
  const { userId } = params
  try{
    let data = await Axios.get(`/api/analytics/getQuickAnalytics?userId=${userId}`);
    return data
  }catch(error){
    throw error
  }
}


function* fetchQuickAnalyticsRequest( params ) {
  try {
    const data = yield call(getQuickAnalyticsData, params);
    yield put(getQuickAnalyticsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* fetchQuickAnalytics() {
  yield takeEvery(types.GET_QUICK_ANALYTICS, fetchQuickAnalyticsRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchQuickAnalytics)])
}