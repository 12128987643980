import * as types from '../../constants/ActionTypes';

export const getSourceListByFilter = (pageNumber, filters) => {
  return {
    type: types.GET_SOURCE_LIST_BY_FILTER,
    pageNumber,
    filters
  }
}

export const getSourceListByFilterSuccess = ({ data }) => {
  return {
    type: types.GET_SOURCE_LIST_BY_FILTER_SUCCESS,
    sourceList: data
  }
}
export const upsertSource = ({ data, filters }) => {
  return {
    type: types.UPSERT_SOURCE,
    sourceUpsert: data,
    filters
  }
}
export const upsertSourceSuccess = ({ data }) => {
  return {
    type: types.UPSERT_SOURCE_SUCCESS,
    sourceUpsert: data
  }
}

export const getSourceSearchFilter = (data) => {
  return {
    type: types.GET_SEARCH_SOURCE_FILTER,
    queryParams: data
  }
}

export const getSourceSearchFilterSuccess = (data) => {
  return {
    type: types.GET_SEARCH_SOURCE_FILTER_SUCCESS,
    sourceSearch: data
  }
}

export const convertOgcToUgc = (data) => {
  return {
    type: types.CONVERT_OGC_TO_UGC,
    payload: data
  }
}

export const resetState = () => {
  return {
    type: types.RESET_SOURCE_STATE
  }
}
