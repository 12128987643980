import React from "react";
import { connect } from 'react-redux';
import QueryString from "query-string"
import { Button, Icon, Modal, message as Message, Layout, Card, Row, Col, Avatar, Collapse } from "antd";
import _ from "lodash"
import { withTranslation } from "react-i18next";
import FacebookAuth from "react-facebook-auth";
import GoogleAuth from "react-google-login";
import ErrorBoundComponent from "../../components/ErrorBoundComponent";
import { namedRoutes } from "../App/flow";
import { getCurrentUser } from "../../appRedux/actions/currenUser.action";
import { login, loginLinkAccount } from "../../clientServices/auth";
import "./styles.css";
import graphics from "../../assets/images/MAIN_GRAPHIC.svg";
import VP_1_GRAPHICS from "../../assets/images/VP_1_GRAPHIC.svg";
import VP_2_GRAPHICS from "../../assets/images/VP_2_GRAPHIC.svg";
import VP_3_GRAPHICS from "../../assets/images/VP_3_GRAPHIC.svg";
import TESTIMONIAL_1_IMAGE from "../../assets/images/011.jpg";
import TESTIMONIAL_2_IMAGE from "../../assets/images/012.jpg";
import TESTIMONIAL_3_IMAGE from "../../assets/images/013.jpg";
import TRUECALLER_ICON from "../../assets/images/truecaller_icon.svg";
import jwt_decode from "jwt-decode";

import Auxiliary from "../../util/Auxiliary";


const FACEBOOK_LOGIN_TYPE = "FACEBOOK", GOOGLE_LOGIN_TYPE = "GOOGLE";

const { Footer } = Layout
const { Meta } = Card
const { Panel } = Collapse


class ExternalLoginContainer extends ErrorBoundComponent {

  constructor(props) {
    super(props);
    this.state = {
      clientId: null
    }
  }

  componentDidMount() {
    document.title = 'DH Creator';

    if (this.props.config && this.props.config.google && this.props.config.google.client_id && !_.isEqual(this.props.config.google.client_id, this.state.clientId)) {
      this.setState({ clientId: this.props.config.google.client_id })

      // Initialization & Configuration of google
      window.google.accounts.id.initialize({
        client_id: this.props.config.google.client_id,
        callback: this._responseGoogle
      });

      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "filled_blue", size: "large", shape: "pill", logo_alignment: "left", width: "260px" }  // customization attributes
      );
      window.google.accounts.id.prompt();
    }
  }

  _tutorialVideo = () => {
    const videoId = this.props.isMobile ? "fFl33UIq8qE" : "LZqJntC7TG4"
    const src = 'https://www.youtube.com/embed/' + videoId
    return (
      <Modal
        width={this.props.isMobile ? "" : 800}
        className={"tutorialVideoModal"}
        style={{ backgroundColor: "transparent" }}
        visible={this.state.showTutorialVideo}
        bodyStyle={{ padding: 0, backgroundColor: "transparent" }}
        onCancel={() => { this.setState({ showTutorialVideo: false }) }}
        footer={null}
        centered
      >
        <iframe
          width={`${this.props.isMobile ? "100%" : 800}`}
          height={`${this.props.isMobile ? 500 : 600}`}
          src={src}
          frameborder="0"
          allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
          allowfullscreen>
        </iframe>
      </Modal>
    )
  }

  _playYoutubeVideo = () => {
    this.setState({
      showTutorialVideo: true
    })
  }

  _MyFacebookButton = ({ onClick }) => (
    <Button style={{ textTransform: "capitalize!important" }} className="social-buttons-facebook" onClick={onClick}>
      <Icon type="facebook" theme="filled" style={{ fontSize: 22 }} />
      <span>{this.props.t("SIGN_IN_WITH_FACEBOOK")}</span>
    </Button>
  );

  _GoogleButton = () => (
    <Button>
      Login with <Icon type="google" />
    </Button>
  );

  _onMobileLogin = () => {
    let queryParams = {}
    queryParams.email = QueryString.parseUrl(this.props.location.search).query.email || {}
    queryParams.source = QueryString.parseUrl(this.props.location.search).query.source || {}

    if (!_.isEmpty(queryParams.email) && !_.isEmpty(queryParams.source)) {
      this.props.history.push(`/login/mobile?${QueryString.stringify(queryParams.email && queryParams.source ? queryParams : "")}`)
    } else {
      this.props.history.push(`/login/mobile`)
    }
  }
  _authenticate = async (response) => {
    let currentUser = Message.loading("Login in progress...");
    let queryParams = {}
    queryParams.email = QueryString.parseUrl(this.props.location.search).query.email || {}
    queryParams.source = QueryString.parseUrl(this.props.location.search).query.source || {}
    const { userID, accessToken: fbAccessToken, expiresIn: fbTokenTTL, name, email } = response;
    const user = {
      loginType: FACEBOOK_LOGIN_TYPE,
      fbUserId: userID,
      agencyLogin: this.props.match.params.loginType === "agency",
      fbAccessToken,
      fbTokenTTL,
      name,
      email
    }
    let targetLocation;
    try {
      if (!_.isEmpty(queryParams.email) && !_.isEmpty(queryParams.source)) {
        if (user.fbUserId) {
          const resp = await loginLinkAccount(user, queryParams)
          if (resp && resp.data && resp.data.nextScreen) {
            this.props.getCurrentUser()
          } else {
            Message.error(JSON.parse(resp.request.response).message || "Login Failed")
          }
          return
        } else {
          currentUser = null
        }
      } else {
        if (user.fbUserId) {
          if (this.props.match.params.loginType === "agency") {
            this.props.setAgencyOnboardingOAuthCredential(user);
          }
          const resp = await login(user)
          if (resp && resp.data && resp.data.nextScreen) {
            this.props.getCurrentUser()
          } else {
            Message.error("Login failed")
          }
        } else {
          currentUser = null
        }
      }
    } catch (error) {
      console.log(error)
      Message.error(error.message || "Login failed")
      console.error(error)
    }
  };

  _responseGoogle = async (response) => {
    let targetLocation, currentUser, progressMsg;
    let queryParams = {}
    queryParams.email = QueryString.parseUrl(this.props.location.search).query.email
    queryParams.source = QueryString.parseUrl(this.props.location.search).query.source
    let decodedRes = jwt_decode(response.credential);

    const user = {
      loginType: GOOGLE_LOGIN_TYPE,
      googleId: decodedRes.sub,
      agencyLogin: this.props.match.params.loginType === "agency",
      googleAccessToken: response.credential,
      googleTokenTTL: decodedRes.exp,
      name: decodedRes.name,
      email: decodedRes.email
    }
    // const { googleId, tokenObj, profileObj } = response;
    // const { id_token, expires_in } = tokenObj
    // const user = {
    //   loginType: GOOGLE_LOGIN_TYPE,
    //   googleId,
    //   agencyLogin: this.props.match.params.loginType === "agency",
    //   googleAccessToken: id_token,
    //   googleTokenTTL: expires_in,
    //   name: profileObj.name,
    //   email: profileObj.email
    // }

    try {
      if (queryParams.email && queryParams.source && user.googleId) {
        if (this.props.match.params.loginType === "agency") {
          this.props.setAgencyOnboardingOAuthCredential(user);
        }
        progressMsg = Message.loading("Login in progress...");
        const loggedInAs = this.props.match.params.loginType ? this.props.match.params.loginType : "external"
        const resp = await loginLinkAccount(user, queryParams)
        if (resp && resp.data && resp.data.nextScreen) {
          this.props.getCurrentUser()
        } else {
          Message.error(JSON.parse(resp.request.response).message || "Login  Failed")
        }
        return
      } else {
        currentUser = null
      }

      if (user.googleId) {
        if (this.props.match.params.loginType === "agency") {
          this.props.setAgencyOnboardingOAuthCredential(user);
        }
        progressMsg = Message.loading("Login in progress...");
        const loggedInAs = this.props.match.params.loginType ? this.props.match.params.loginType : "external"
        const resp = await login(user)
        if (resp && resp.data && resp.data.nextScreen) {
          // targetLocation = _.get(namedRoutes,resp.data.nextScreen)
          this.props.getCurrentUser()
        } else {
          Message.error("Login failed")
        }
      } else {
        currentUser = null
      }
    } catch (error) {
      console.log(error.data, error.request, error.message, "error.messageerror.message")
      Message.error(error.message || "Login failed")
    } finally {
      // targetLocation && this.props.history.replace(targetLocation)
    }
  };

  _getLoginContent = () => {
    return (
      <div className={!this.props.isMobile ? 'd-flex justify-content-around' : 'justify-content-center'}>
        <div style={this.props.isMobile ? { width: "96%" } : { width: "50%", position: "relative" }}>
          <div className="d-flex flex-wrap tagline">
            {this.props.t("HOME_MAIN_HEADING")}
          </div>
          <div className="d-flex sub-tagline">
            {this.props.t("HOME_SUB_HEADING")}
          </div>
          <div className={'justify-content-center'}>
            <div className="login-buttons-container">
              {this.props.region !== "DH_MENA" && <Button onClick={this._onMobileLogin} className="social-buttons-mobile" >
                <Icon component={() => <img src={TRUECALLER_ICON} />} style={{ fontSize: 22 }} />
                <span>Sign in with phone number</span>
              </Button>}
            </div>
          </div>
          <div className={'justify-content-center'}>
            <div className="login-buttons-container" style={{ width: "260px" }}>
              <div id="buttonDiv"></div>
              {/* <GoogleAuth
                clientId={this.props.config["google"]["client_id"]}
                tag="div"
                render={(renderProps) => {
                  return (
                    <Button
                      className="social-buttons-google"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <Icon type="google" style={{ fontSize: 22 }} />
                      <span>{this.props.t("SIGN_IN_WITH_GOOGLE")}</span>
                    </Button>
                  )
                }}
                onSuccess={this._responseGoogle}
                onFailure={this._responseGoogle}
                cookiePolicy={"single_host_origin"}
              /> */}
            </div>
           { 
           
            <div className="login-buttons-container">
              <FacebookAuth
                appId={this.props.config["facebook"]["app_id"]}
                callback={this._authenticate}
                component={this._MyFacebookButton}
                isMobile={false}
              />
            </div>
            }
          </div>
          <div onClick={this._playYoutubeVideo} className={!this.props.isMobile ? 'd-flex justify-content-start how-to-register' : 'd-flex justify-content-center how-to-register'}>
            <Icon type="play-circle" theme="filled" />
            <div style={{ marginLeft: '5px' }}>{this.props.t("HOW_TO_REGISTER")}</div>
          </div>
        </div>
        <div style={this.props.isMobile ? { width: "96%" } : { width: "50%" }}>
          <img style={{ height: `${!this.props.isMobile ? "33rem" : ""}` }} src={graphics} />
        </div>
      </div>
    )
  }

  _getPerksCards = () => {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <Card
              style={{ minHeight: '360px' }}
              hoverable={false}
              cover={<img alt="example" src={VP_1_GRAPHICS} />}
            >
              <div style={{ paddingBottom: "10px", fontSize: "16px" }}>
                <b>{this.props.t("VP_1_HEADING")}</b>
              </div>
              <div>
                {this.props.t("VP_1_EXPLANATION")}
              </div>
            </Card>
          </div>
          {/* <div className="col-lg-4 col-sm-12">
            <Card
              style={{ minHeight: '360px' }}
              hoverable={false}
              cover={<img alt="example" src={VP_2_GRAPHICS} />}
            >
              <div style={{ paddingBottom: "10px", fontSize: "16px" }}>
                <b>{this.props.t("VP_2_HEADING")}</b>
              </div>
              <div>
                {this.props.t("VP_2_EXPLANATION")}
              </div>
            </Card>
          </div> */}
          <div className="col-lg-6 col-sm-12">
            <Card
              style={{ minHeight: '360px' }}
              hoverable={false}
              cover={<img alt="example" src={VP_3_GRAPHICS} />}
            >
              <div style={{ paddingBottom: "10px", fontSize: "16px" }}>
                <b>{this.props.t("VP_3_HEADING")}</b>
              </div>
              <div>
                {this.props.t("VP_3_EXPLANATION")}
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }

  _getTestimonials = () => {
    return (
      <div className="testimonials">
        <div className="row">
          < div className="col-lg-12" >
            <div className="section-heading">What our Creators have to say ?</div>
          </div>
        </div >
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <Card
              style={{ marginBottom: 10, height: '22rem' }}
              hoverable={false}
            >
              <Meta style={{ whiteSpace: "normal" }} avatar={<Avatar src={TESTIMONIAL_1_IMAGE} shape="square" size="large" />} title={this.props.t('TESTIMONIAL_1_CREATOR_NAME')} />
              <div style={{ marginTop: 30 }}>
                <div className="w3-panel w3-light-grey">
                  <span style={{ fontSize: "150px", lineHeight: "0.6em", opacity: 0.2 }}>❝</span>
                  <p style={{ marginTop: "-40px" }}>
                    <i>
                      {this.props.t('TESTIMONIAL_1_TEXT')}
                    </i>
                  </p>
                </div>
              </div>
            </Card>
          </div>
          < div className="col-lg-4 col-sm-12">
            <Card
              style={{ marginBottom: 10, height: '22rem' }}
              hoverable={false}
            >
              <Meta style={{ whiteSpace: "normal" }} avatar={<Avatar src={TESTIMONIAL_3_IMAGE} shape="square" size="large" />} title={this.props.t('TESTIMONIAL_2_CREATOR_NAME')} />
              <div style={{ marginTop: 30 }}>
                <div className="w3-panel w3-light-grey">
                  <span style={{ fontSize: "150px", lineHeight: "0.6em", opacity: 0.2 }}>❝</span>
                  <p style={{ marginTop: "-40px" }}>
                    <i>
                      {this.props.t('TESTIMONIAL_2_TEXT')}
                    </i>
                  </p>
                </div>
              </div>
            </Card>
          </div>
          < div className="col-lg-4 col-sm-12">
            <Card
              style={{ marginBottom: 10, height: '22rem' }}
              hoverable={false}
            >
              <Meta style={{ whiteSpace: "normal" }} avatar={<Avatar src={TESTIMONIAL_2_IMAGE} shape="square" size="large" />} title={this.props.t('TESTIMONIAL_3_CREATOR_NAME')} />
              <div style={{ marginTop: 30 }}>
                <div className="w3-panel w3-light-grey">
                  <span style={{ fontSize: "150px", lineHeight: "0.6em", opacity: 0.2 }}>❝</span>
                  <p style={{ marginTop: "-40px" }}>
                    <i>
                      {this.props.t('TESTIMONIAL_3_TEXT')}
                    </i>
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div >
      </div>
    )
  }

  _getFaqs = () => {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12" >
            <div className="section-heading">FAQs</div>
          </div>
          <div style={{ width: "100%" }}>
            <Collapse bordered={false} defaultActiveKey={['1']}>
              <Panel header={<div style={{ fontSize: "18px" }}>How to login</div>} key="1">
                <div style={{ color: "rgb(142, 142, 142)" }}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                  Ut non massa vitae risus fermentum ullamcorper.
                  Phasellus risus urna, ornare in aliquam id,
                  porttitor sit amet sapien. Nulla facilisi.
                </div>
              </Panel>
              <Panel header={<div style={{ fontSize: "18px" }}>How to Create Post</div>} key="2">
                <div style={{ color: "rgb(142, 142, 142)" }}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                  Ut non massa vitae risus fermentum ullamcorper.
                  Phasellus risus urna, ornare in aliquam id,
                  porttitor sit amet sapien. Nulla facilisi.
                </div>
              </Panel>
              <Panel header={<div style={{ fontSize: "18px" }}>How to Publish Post</div>} key="3">
                <div style={{ color: "rgb(142, 142, 142)" }}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                  Ut non massa vitae risus fermentum ullamcorper.
                  Phasellus risus urna, ornare in aliquam id,
                  porttitor sit amet sapien. Nulla facilisi.
                </div>
              </Panel>
            </Collapse>
          </div>
        </div >
      </div>
    )
  }

  _render = () => {
    return (
      <Auxiliary>
        <div style={{ width: "100%" }} className="bg-section">
          <div className="external-section">
            {this._getLoginContent()}
          </div>
        </div>
        <div>
          <div className="external-section vp-cards" style={{ marginTop: "20px" }}>
            {this._getPerksCards()}
          </div>
        </div>
        <div style={{ width: "100%", paddingTop: "1rem", paddingBottom: "1rem", backgroundColor: "rgba(244, 244, 244, 0.44)" }} >
          <div className="external-section" style={{ marginTop: "20px" }}>
            {this._getTestimonials()}
          </div>
        </div>
        {/* <div style={{ width: "100%", paddingTop: "1rem", paddingBottom: "1rem" }} >
          <div className="external-section" style={{ marginTop: "20px" }}>
            {this._getFaqs()}
          </div>
        </div> */}
        <Footer className="footer-container">
          <div className="external-section" style={{ marginTop: "20px" }}>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="footer-links-container">
                  {/* <a className="footer-link" href="#">Privacy Policy</a> */}
                  {/* <a className="footer-link" target="_blank" href="/legal-docs/dh-creator-t-n-c.pdf">Terms and Conditions</a> */}
                  {/* <a className="footer-link" href="#">Terms of Use</a> */}
                </div>
              </div>
              <div style={{ display: "flex", flexFlow: "column", alignItems: "center", justifyContent: "center" }}>
                <div style={{ padding: 5 }}>
                  Contact Us : creators@dailyhunt.in
                </div>
                <div style={{ padding: 5 }}>
                  Copyright © 2019 Dailyhunt Inc. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </Footer>
        {this.state.showTutorialVideo && this._tutorialVideo()}
      </Auxiliary>
    )
  }
}

function mapStateToProps(store) {
  console.log( store );
  return {
    isMobile: _.get(store, "common.isMobile"),
    region : _.get( store, "common.region"),
    config: _.get(store, "config.config")
  }
}

export default connect(mapStateToProps, { getCurrentUser })(withTranslation()(ExternalLoginContainer));