import {
	Form,
	Input,
	Tooltip,
	Badge
} from "antd";
import PropTypes from "prop-types";
import React from "react";
import InputMask from "react-input-mask";
import _ from "lodash";
import ValidatorComponent from "./validator_component";

import {
	FormContextItem
} from "./form_context";

class TextField extends ValidatorComponent {
	static propTypes = {
		disabled: PropTypes.bool,
		label: PropTypes.string,
		layout: PropTypes.object,
		toolTipLabel: PropTypes.string,
		placeholderLabel: PropTypes.string,
		className: PropTypes.string,
		allowClear: PropTypes.bool,
		value: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.string,
			PropTypes.number,
			PropTypes.any
		]),
		defaultValue: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.string,
			PropTypes.number,
			PropTypes.any
		]),
		suffix: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		])
	};

	_onChange = async (e) => {
		if (!this.props.value) {
			this.setState({
				value: e.target.value,
				defaultUpdated: true
			}, () => {
				if (this.props.onChange) {
					this.props.onChange(this.state.value);
				}
			});
		} else {
			if (this.props.onChange) {
				await this.props.onChange(e.target.value);
				if (this.state.error) {
					this._validate();
				}
			}
		}
	};

	_inputRenderer = () => {
		if (!this.props.disabled && this.props.mask) {
			return (
				<InputMask
					maskChar=""
					mask={this.props.mask}
					value={this.state.value}
					onChange={this._onChange}
					disabled={false}
				>
					{(inputProps) => (
						<Input
							{...inputProps}
							prefix={this.props.prefix}
							addonBefore={this.props.addonBefore}
							type={this.props.inputType}
							style={this.props.style}
							placeholder={this.props.placeholderLabel}
							size={this.props.inputFieldSize ? this.props.inputFieldSize : "large"}
							maxLength={this.props.maxLength}
						/>
					)}
				</InputMask>
			);
		}
		if (this.props.inputType === "password") {
			return (
				<Input.Password
					style={this.props.style}
					value={this.state.value}
					disabled={this.props.disabled ? true : false}
					type={this.props.inputType}
					placeholder={this.props.placeholderLabel}
					onChange={this._onChange}
					onPressEnter={this.props.onPressEnter}
					size={this.props.inputFieldSize ? this.props.inputFieldSize : "large"}
					suffix={this.props.suffix}
				/>
			)
		}
		return (
			<Input
				style={this.props.style}
				prefix={this.props.prefix}
				addonBefore={this.props.addonBefore}
				value={this.state.value}
				onBlur={this.props.onBlur}
				// defaultValue={this.props.defaultValue}
				disabled={this.props.disabled ? true : false}
				type={this.props.inputType}
				placeholder={this.props.placeholderLabel}
				onChange={this._onChange}
				onPressEnter={this.props.onPressEnter}
				size={this.props.inputFieldSize ? this.props.inputFieldSize : "large"}
				suffix={this.props.suffix}
				allowClear={this.props.allowClear}
				maxLength={this.props.maxLength}
				{...this.props.additionalInputProps}
			/>
		);
	}

	_inputContainer = () => {
		if (this.props.toolTipLabel) {
			return (
				<Tooltip
					title={this.props.toolTipLabel}
					trigger={["focus"]}
					placement="bottom"
				>
					{this._inputRenderer()}
				</Tooltip>
			);
		}
		return this._inputRenderer();
	};

	_getlabel = () => {
		if (this.props.label && (_.includes(this.props.validations, "required") || _.includes(this.props.validations, "requiredArr"))) {
			return (
				<div style={{ display: 'flex' }}>
					<div>
						{this.props.label}
					</div>
					<Badge style={{ marginTop: 4 }} dot>
					</Badge>
				</div>
			)
		} else {
			return this.props.label
		}
	}

	_render = () => {
		return (
			<Form.Item
				validateStatus={this.state.error || !this.props.overRideValidateStatus ? this.state.error ? "error" : "success" : this.props.overRideValidateStatus}
				help={this.state.error ? this.state.error : this.props.customError}
				label={this._getlabel()}
				className={this.props.className}
				{...this.props.layout}
			>
				{this._inputContainer()}
			</Form.Item>
		);
	}
}

export default FormContextItem(TextField)