import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import _ from "lodash"
import {
  getLocations,
  getLanguages,
  getDhTags
} from "../actions";
import { fetchError } from "../actions/common.action";
import * as types from '../../constants/ActionTypes';



function* fetchPostDependenciesRequest( params ) {
  try {
    yield all([
      put( getLocations ),
      put( getLanguages ),
      put( getDhTags )
    ])
  } catch (error) {
    throw error
  }
}

export function* fetchPostDependencies() {
  yield takeEvery(types.GET_POST_DEPENDENCIES, fetchPostDependenciesRequest );
}


export default function* rootSaga() {
  yield all([fork(fetchPostDependencies)])
}