import React, { Component } from "react";
import _ from "lodash";
import {connect} from 'react-redux';
import CircularProgress from "../components/CircularProgress";
import { Result } from "antd";
import {resetError} from "../appRedux/actions/common.action";

class Auxiliary extends Component{

  constructor(props) {
    super(props)
  }

  componentWillUnmount(){
    if(this.props.error && this.props.error != ""){
      this.props.resetError();
    }
  }

  render(){
    const props = this.props;
    if (props.error) {
      console.error(props.error)
      return (
        <Result
          status={_.get( props.error, "response.status" ) || 500}
          subTitle={ _.get( props.error, "response.data.message") || _.get( props.error, "message") || "Something went wrong !!" }
        />
      )
    }
    if (props.loading) {
      return <CircularProgress />
    }
    return props.children;
  }
}

export default connect(null, { resetError })(Auxiliary);
