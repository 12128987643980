import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Axios from "axios";
import {notification as Notification,message} from "antd";
import _Get from "lodash/get";
import {getWorkApprovalListByFilter,getWorkApprovalListByFilterSuccess,
        updateWorkApprovalDetailsSuccess,
        viewWorkApprovalListByFilterSuccess }from "../actions/workApproval.action";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";

const getWorkApprovalByFilter = async (params) => {
  try{
    let data = await Axios.post(`/api/workApproval/getworkApprovalByFilter`,{
      approvalType: params.approvalType
    });
    return data;
  }catch(error){
    throw error;
  }
}

const viewWorkApprovalByFilter = async (params) => {
  try{
    let data = await Axios.post(`/api/workApproval/viewWorkApprovalByFilter`,{
      creatorUserId: params.creatorUserId,
      userId:params.userId
    });
    return data.data;
  }catch(error){
    throw error;
  }
}

const updateWorkApprovalDetail= async ( params ) =>{
  try{
   
    let resp = await Axios.post(`/api/workApproval/updateDetailsStatus`,{
      creatorUserId:params.creatorUserId,
      action:params.modifiedAction,
      userId:params.userId
    });
    if (resp.data && resp.data.status === 200) {
      Notification.success({
       message: "Success"
      })
    } else {
      Notification.error({
       message: _Get(resp, "data.message") || "Something went wrong !!"
      })
    }
    return resp;
  }catch(error){
    throw error;
  }
}
function* updateWorkApprovalDetailRequest(params){
  try{
    const data = yield call(updateWorkApprovalDetail,params)
    yield put(updateWorkApprovalDetailsSuccess(data))
    yield call(fetchWorkApprovalListRequest,{approvalType:"WORK"});
    }catch(error){
    yield put(fetchError(error));
  }
}
export function* updateWorkApprovalDetailGeneratorMap() {
  yield takeEvery(types.UPDATE_WORK_APPROVAL_DETAILS, updateWorkApprovalDetailRequest);
}

function* fetchWorkApprovalListRequest(params){
  try{
    const data = yield call(getWorkApprovalByFilter,params)
    
    yield put(getWorkApprovalListByFilterSuccess(data))
  }catch(error){
    yield put(fetchError(error));
  }
}

function* ViewWorkApprovalListRequest(params){
  try{
    const data = yield call(viewWorkApprovalByFilter,params);
    yield put(viewWorkApprovalListByFilterSuccess({data}));
    // yield call(fetchWorkApprovalListRequest,{approvalType:"WORK"});
  }catch(error){
    yield put(fetchError(error));
  }
}
export function* viewWorkApprovalDetailGeneratorMap() {
  yield takeEvery(types.VIEW_WORK_APPROVAL_LIST_BY_FILTER, ViewWorkApprovalListRequest);
}

export function* fetchWorkApprovalList() {
  yield takeEvery(types.GET_WORK_APPROVAL_LIST_BY_FILTER,fetchWorkApprovalListRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchWorkApprovalList),fork(updateWorkApprovalDetailGeneratorMap),fork(viewWorkApprovalDetailGeneratorMap)])
}
