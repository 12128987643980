import * as types from '../../constants/ActionTypes';

export const getLanguages = () => {
  return {
    type: types.GET_LANGUAGES
  }
}

export const getLanguagesSuccess = ({ data }) => {
  return {
    type: types.GET_LANGUAGES_SUCCESS,
    languageList: data
  }
}
