import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import _ from "lodash"
import { namedRoutes } from "../../containers/App/flow";
import AppRoutes from "./appRoutes";
import ErrorBoundComponent from "../../components/ErrorBoundComponent";
import OnboardingRoutes from "./onboardingRoutes";

class LoginDependentRoutes extends ErrorBoundComponent {
  _render = () => {
    return (
      <Switch>
        <Route path="/"
          render={(props) => {
            let userNextScreen = _.get(this.props, "nextScreen")
            if (userNextScreen === "dashboard" || userNextScreen === "post-listing") {
              return (
                <AppRoutes {...this.props} currentUser={this.props.currentUser} />
              )
            } else {
              return <OnboardingRoutes  {...this.props} currentUser={this.props.currentUser} />
            }
          }}
        />
        {/* <Route
          path="/verify-phone"
          exact
          render={(props) => {
            return(
              <div> Phone Verification </div>
            )
          }
            // <AuthPhoneContainer {...props} userId={this.props.currentUser ? this.props.currentUser.userId : null} logout={this.props.resetCurrentUser} />
          }
        /> */}
      </Switch>
    )
  }
}

export default LoginDependentRoutes;
