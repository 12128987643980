import * as types from '../../constants/ActionTypes';

const initialState = {
  workApprovalList:[], 
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    case types.GET_WORK_APPROVAL_LIST_BY_FILTER:
      return {
        ...state,
        loading:true,
        workApprovalList: state.workApprovalList
      }
    case types.GET_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading:false,
        workApprovalList: action.workApprovalList,
      }
    case types.RESET_WORK_APPROVAL_STATE:
      return{
          ...state,
          workApprovalList: []
        }
      case types.VIEW_WORK_APPROVAL_LIST_BY_FILTER:
      return {
        ...state,
        loading:false,
      }
      case types.VIEW_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading:false,
        workApprovalById: action.workApprovalList,
      }
    default:
      return state
  }
}
