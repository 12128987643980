import * as types from '../../constants/ActionTypes';

export const getWorkApprovalListByFilter = (approvalType) => {
  return {
    type: types.GET_WORK_APPROVAL_LIST_BY_FILTER,
    approvalType
  }
}

export const getWorkApprovalListByFilterSuccess = ({data}) => {
  return {
    type: types.GET_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS,
    workApprovalList: data
  }
}
export const viewWorkApprovalListByFilter = ({data,userId}) => {
  return {
    type: types.VIEW_WORK_APPROVAL_LIST_BY_FILTER,
    creatorUserId:data,
    userId:userId
  }
}
export const viewWorkApprovalListByFilterSuccess = ({data}) => {
  return {
    type: types.VIEW_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS,
    workApprovalList: data
  }
}
export const updateWorkApprovalDetailsSuccess =({data})=>{
  return{
    type:types.UPDATE_WORK_APPROVAL_DETAILS_SUCCESS,
    creatorUserId:data
  }
}

export const updateWorkApprovalDetails =({data,action,userId})=>{
  return{
    type:types.UPDATE_WORK_APPROVAL_DETAILS,
    creatorUserId:data,
    modifiedAction:action,
    userId:userId
  }
}
export const resetState = () => {
  return {
    type: types.RESET_WORK_APPROVAL_STATE
  }
}