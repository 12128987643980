import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import _ from "lodash"
import _Map from "lodash/map";
import _Get from "lodash/get";
import { getProfileDetails } from "../../appRedux/actions/profile.action";
import ErrorBoundComponent from "../ErrorBoundComponent";
import AppModuleHeader from "../../components/AppModuleHeader/appModuleHeader";
import "./dashBoardLayout.styles.css";
import { oboardingConstraints, premiumUserFeature } from "../../containers/App/flow";
import { resetNewUserCookie } from "../../clientServices/auth";
import AccountsLinkingModal from "../AccountsLinking/accountsLinking"
import OnBoardingStepsBar from "../../containers/OnboardingFlows/onBoardingStepsBar";
const { Content } = Layout

class DashBoardLayout extends ErrorBoundComponent {

  constructor(props) {
    super(props)
    this.state = {
      showOnboardingModal: false,
      showLanguageSelector: false,
      profileDetails: null
    }
  }


  componentDidMount() {
    const { currentUser } = this.props
    if (currentUser) {
      if (currentUser.nextScreen === "dashboard") {
        const userId = currentUser.userId
        userId && this.props.getProfileDetails(userId)
      }
      if (currentUser.creatorState) {
        const onboardingScreen = this._getOnboardingScreen(currentUser.creatorState)
        this.setState({
          onboardingScreen: onboardingScreen
        })
      }
    }
    if (typeof window !== undefined) {
      const cookieArray = document.cookie ? document.cookie.split("; ") : [];
      const newUserCookie = cookieArray.filter(e => e.includes("newUser"));
      const newUser = newUserCookie.length ? newUserCookie[0].replace("newUser=", "").trim() : null;
      this.setState({
        newUser: newUser === "true"
      })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (_.get(prevProps, "profileDetails.creatorId") !== _.get(this.props, "profileDetails.creatorId")) {

      const { currentUser } = this.props
      await this.setState({
        profileDetails: this.props.profileDetails
      })
      const onboardingScreen = this._getOnboardingScreen(currentUser.creatorState)
      this.setState({
        onboardingScreen: onboardingScreen
      })
    }
  }

  _getOnboardingScreen = (creatorState) => {
    let onboardingScreen;
    if (creatorState) {
      let onBoardingFeature = [...oboardingConstraints]
      if (_.get(this.props, "currentUser.creatorState.onboarding-complete") == "true" && !_.isEmpty(_.get(this.state, "profileDetails")) && !_.isEmpty(_.get(this.state, "profileDetails.metaInfo")) && _.get(this.state, "profileDetails.metaInfo.paymentType") == "ICC" || _.get(this.state, "profileDetails.metaInfo.paymentType") == "OGC") {
        onBoardingFeature = [...oboardingConstraints, ...premiumUserFeature]
      }

      _.map(onBoardingFeature, ({ state, screen }) => {
        if (_.isMatch(creatorState, state)) {
          onboardingScreen = screen
        }
      })
    }
    return onboardingScreen
  }


  _accountLinkingModal = () => {
    return (
      <AccountsLinkingModal
        visible={this.state.newUser}
        isMobile={this.props.isMobile}
        userId={_.get(this.props, "currentUser.userId")}
        resetNewUser={() => {
          this.setState({
            newUser: false
          }, async () => {
            await resetNewUserCookie()
          })
        }}
      />
    )
  }


  _render = () => {
    return (
      <Layout style={{ overflow: "hidden !important", height: "100vh" }}>
        {/* <OnBoardingStepsBar toggleOnBoardingModal={ this._toggleOnBoardingModal } onboardingScreen={ this.state.onboardingScreen} /> */}
        <AppModuleHeader
          postType={this.props.postType}
          onboardingScreen={this.state.onboardingScreen}
          appLanguage={this.props.appLanguage}
          toggleLanguageSelector={this.props.toggleLanguageSelector}
          {...this.props}
        />
        <Content style={{ height: "100%" }}>
          <div
            style={{
              marginTop: `${this.state.onboardingScreen && !this.props.isMobile ? "8rem" : ""}`,
              height: `${this.state.onboardingScreen && !this.props.isMobile ? "calc( 100% - 6rem)" : ""}`
            }}
            className="content-wrapper" >
            {
              this.props.children
            }

          </div>
        </Content>
        {(this.state.newUser && !this.props.isOgcToIcc) && this._accountLinkingModal()}
      </Layout>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    currentUser: _.get(store, "currentUser.currentUser.data"),
    isMobile: _.get(store, "common.isMobile"),
    profileDetails: _.get(store, "profile.profileDetails.data.row.0"),
    isOgcToIcc: _.get(store, "isOgcToIcc.isOgcToIcc"),
  }
}

export default connect(mapStateToProps, { getProfileDetails })(DashBoardLayout)