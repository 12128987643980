import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Button, message, Row, Col, Icon, Tag } from "antd"
import _ from "lodash"
import ValidatorForm, { TextField } from "../../ValidatorForm";

import ErrorBoundComponent from "../../../components/ErrorBoundComponent";
import { Register } from "../../../clientServices/profile";

import {
  getCurrentUser
} from "../../../appRedux/actions/currenUser.action";

import {
  setConflictAccounts
} from "../../../appRedux/actions/common.action";

import "./styles.css"
class PhoneVerification extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      isTimerOn: false,
      setTimer: false,
      second: 60,
      phone: "",
      otp: "",
      otpDisable: true
    }
  }

  _onSubmit = async () => {
    if (this.state.phone.length < 10) {
      message.error("Phone number should be 10 digits only");
      return;
    }

    if (this.state.phone && this.state.otp) {
      message.loading("Action in progress...")
      const profile = {
        phone: this.state.phone,
        otp: this.state.otp,
        action: this.props.verifyOtpAction
      };
      const user = { userId: this.props.userId };

      const response = await Register(profile, user);
      if (response.status === 200 && response.data ) {
        if( response.data.selectAccount){
          this.props.setConflictAccounts(response.data.selectAccount)
        }
        message.success(response.message)
      } else {
        message.error(_.get(response, "data.message") || response.message || "Otp verification failed")
      }
      this.props.getCurrentUser()
    }

  }

  componentDidUpdate(preState) {
    if (this.state.isTimerOn && preState.isTimerOn != this.state.isTimerOn) {
      if (this.state.setTimer) {
        this.setState({
          setTimer: false
        })
        this.timer = setInterval(() => this.setState({
          second: this.state.second - 1
        }), 1000)
        this.stopTimer = setTimeout(() => {
          clearInterval(this.timer);
          this.setState({
            isTimerOn: false
          })
        }, 60000)
      }
    }
  }

  _sendOtp = async () => {
    if (this.state.phone.length === 10) {
      message.loading("Action in progress...")
      const profile = { phone: this.state.phone, action: this.props.sendOtpAction };
      const user = { userId: this.props.userId };
      const response = await Register(profile, user)
      if (response.status === 200) {
        message.success("Otp Sent")
        this.setState({
          second: 60,
          isTimerOn: true,
          setTimer: true,
          otpDisable: false
        })
      } else {
        message.error(_.get(response, "data.message") || response.message || "Otp sending failed")
      }
    } else {
      message.error("Phone number should be 10 digits only");
    }
  }

  _content = () => {
    return (
      <ValidatorForm
        layout="vertical"
        onSubmit={this._onSubmit}
      >
        <Row>
          <TextField
            placeholderLabel={"Phone Number"}
            field="phone"
            mask="9999999999"
            onChange={(e) => {
              this.setState({ phone: e, isTimerOn: false });
              clearInterval(this.timer);
              clearTimeout(this.stopTimer);
            }}
            inputFieldSize="default"
            validations={["required", "phoneNumber"]}
            errors={["Please Enter a Valid Phone Number"]}
          />
          <Button
            type="primary"
            field="sendOTP"
            // size="default"
            onClick={this._sendOtp}
            disabled={this.state.isTimerOn || this.props.loading}
            onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); return; }}
            shape={this.state.isTimerOn ? "circle" : ""}
          >
            {this.state.isTimerOn ? this.state.second : "Send OTP"}
          </Button>
        </Row>
        <Row>
          <TextField
            placeholderLabel={"Enter OTP"}
            field="otp"
            // mask="9999"
            // inputType="number"
            inputFieldSize="default"
            disabled={this.state.otpDisable}
            validations={["required", "number"]}
            errors={["Please Enter Valid OTP"]}
          />
          <Button
            type="primary"
            htmlType="submit"
            field="verify"
            size="default"
            disabled={this.state.otpDisable || this.props.loading}
            onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); return; }}
          // ghost={this.state.otpDisable}
          >
            {"Verify"}
          </Button>
        </Row>
      </ValidatorForm>
    );
  }

  _enterOtp = (e) => {
    this.setState({
      otp: e.target.value
    })
  }

  _editMobile = () => {
    this.setState({
      otpDisable: true
    })
  }

  _otpScreen = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}>
                {this.props.t("ENTER_OTP")} {this.state.phone ? `+91-${this.state.phone}` : "+91-XX-XX-XX-XX-XX"}
              </div>
              {
                this.state.isTimerOn ? "" :
                  <Tag onClick={this._editMobile} type="primary" size={"small"} shape={"round"} color={"green"}>
                    {this.props.t("EDIT_PHONE")}
                  </Tag>
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }} >
            <input onChange={this._enterOtp} className="otp_input" maxlength='4' />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            {this.state.isTimerOn ? this.state.second : <Tag onClick={this._sendOtp} >{this.props.t("RESEND_OTP")}</Tag>}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              style={{ width: "100%", marginTop: 10 }}
              type={this.state.otp.length !== 4 ? "ghost" : "primary"}
              size="default"
              onClick={this._onSubmit}
              disabled={this.state.otp.length !== 4}
              onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); return; }}
            >
              {this.props.t("SUBMIT_OTP")}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  _mobile = () => {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "12px" }}>
          {this.props.t("INPUT_PHONE")}
        </div>
        <Row>
          <Col span={24}>
            <TextField
              addonBefore="+91"
              placeholderLabel={this.props.t("PHONE_NUMBER")}
              field="phone"
              mask="9999999999"
              onChange={(e) => {
                this.setState({ phone: e, isTimerOn: false });
                clearInterval(this.timer);
                clearTimeout(this.stopTimer);
              }}
              inputFieldSize="default"
              validations={["required", "phoneNumber"]}
              errors={["Please Enter a Valid Phone Number"]}
              defaultValue={this.state.phone ? this.state.phone : ""}
            />
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              field="sendOTP"
              style={{ width: "100%", marginTop: 10 }}
              // size="default"
              onClick={this._sendOtp}

              disabled={this.state.isTimerOn || this.props.loading}
              onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); return; }}
              shape={this.state.isTimerOn ? "circle" : ""}
            >
              {this.state.isTimerOn ? this.state.second : this.props.t("GET_OTP")}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  _render = () => {
    console.log(this)
    return (
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1>{this.props.t("OTP_HEADING")}</h1>
        </div>
        {this.state.otpDisable ? this._mobile() : this._otpScreen()}
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  return store
}

export default connect(mapStateToProps, { getCurrentUser, setConflictAccounts })(withTranslation()(PhoneVerification))