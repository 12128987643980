import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Axios from "axios";

import {notification as Notification,message} from "antd";
import _Get from "lodash/get";
import {
  getAgencyListByFilter,
  getAgencyListByFilterSuccess,
  upsertAgencySuccess
} from "../actions/agency.action";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";

const getAgencyByFilter = async () => {
  try{
    let data = await Axios.post(`/api/agency/getAgencyByFilter`);
  
    return data;
  }catch(error){
    throw error;
  }
}

const upsertAgency = async (params) =>{
  try{
    let data = await Axios.post(`/api/agency/upsert`,{agencyData:params.agencyUpsert});
    if (data.status === 200) {
      Notification.success({
        message: "Success"
      })
    } else {
      Notification.error({
        message: _Get(data, "data.message") || "Something went wrong !!"
      })
    }
    return data;
  }catch(error){
    throw error;
  }
}

function* upsertAgencyRequest(params){
  try{
    const data = yield call(upsertAgency,params)
    yield call(fetchAgencyListRequest);
    yield put(upsertAgencySuccess(data))
    }catch(error){
    yield put(fetchError(error));
  }
}

function* fetchAgencyListRequest(){ 
  try{
    const data = yield call(getAgencyByFilter)
    yield put(getAgencyListByFilterSuccess(data))
  }catch(error){
    yield put(fetchError(error));
  }
}

export function* fetchAgencyList() {
  yield takeEvery(types.GET_AGENCY_LIST_BY_FILTER, fetchAgencyListRequest);
}

export function* upsertAgencyGeneratorMap() {
  yield takeEvery(types.UPSERT_AGENCY, upsertAgencyRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchAgencyList),fork(upsertAgencyGeneratorMap)])
}
