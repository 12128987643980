import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash"
import { Avatar, Popover, Icon } from "antd";
import { Select } from "../../containers/ValidatorForm";
import { PERMISSION_UPDATE_PROFILE } from "../../constants/Permissions";

class UserInfo extends Component {

  render() {
    const permissions = _.get(this.props, "currentUser.permissions.length") > 0 ? _.get(this.props, "currentUser.permissions") : []
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {permissions.includes(PERMISSION_UPDATE_PROFILE) && <li onClick={this.props.myProfile}>{this.props.t("USERMENU_PROFILE")}</li>}
        <li onClick={this.props.onLogout}><Icon type="poweroff" /> {this.props.t("USERMENU_LOGOUT")}</li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar 
          icon="user"
          src={this.props.profileImageUrl ? this.props.profileImageUrl : null}
          alt="" 
          size="large"
        />
      </Popover>
    )

  }
}

export default withTranslation()(UserInfo);
