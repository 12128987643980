import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";

import {
  uploadImage,
  uploadImageSuccess
} from "../actions/upload.action"

const uploadImageAxios = async (params) => {
  try{
    let data = await Axios.post(`/api/image`, {
      imageObject : params.file
    });
  }catch(error){
    throw error;
  }
}

function* uploadImageRequest(params){
  try{
    const data = yield call(uploadImageAxios, params)  
    yield put(uploadImageSuccess(data))
  }catch(error){
    yield put(fetchError(error));
  }
}

export function* upload() {
  yield takeEvery(types.UPLOAD_IMAGE, uploadImageRequest);
}


export default function* rootSaga() {
  yield all([fork(upload)])
}
