import { SET_POST_TYPE, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, SET_MOBILE_VIEW, RESET_ERROR, SET_CONFLICT_ACCOUNTS, SET_UTM_PARAMS, SET_REGION } from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const setMobileView = (isMobileView) => {
  return {
    type: SET_MOBILE_VIEW,
    isMobileView: isMobileView
  }
};

export const setRegion = (region ) =>{
  return {
    type: SET_REGION,
    region: region
  }
}

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const resetError = () => {
  return {
    type: RESET_ERROR
  }
};

export const setConflictAccounts = ( accounts ) => {
  return {
    type: SET_CONFLICT_ACCOUNTS,
    accounts: accounts
  }
};

export const setUtmParams = (utmParams, currentUserEmail) => {
  return {
    type: SET_UTM_PARAMS,
    utmParams,
    currentUserEmail
  }
};

export const setPostType = (postType) => {
  return {
    type: SET_POST_TYPE,
    postType
  }
};