import * as types from '../../constants/ActionTypes';

const initialState = {
  accountList:[], 
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    
    case types.GET_ACCOUNT_LIST_BY_FILTER:
      return {
        ...state,
        loading:true,
        accountList: state.accountList
      }
    case types.GET_ACCOUNT_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading:false,
        accountList: action.accountList,
      }
      case types.UPSERT_ACCOUNT:
        return{
            ...state,
            loading:true,
            accountUpsert:state.accountUpsert
          }
      case types.UPSERT_ACCOUNT_SUCCESS:
        return{
           ...state,
           loading:false,
            accountUpsert:action.accountUpsert
          }
      case types.RESET_ACCOUNT_STATE:
      return {
        ...state,
        accountList: []
      }
    default:
      return state
  }
}
