import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Axios from "axios";

import {
  setCurrentUserSuccess,
  getCurrentUserSuccess,
  logoutUserSuccess
} from "../actions/currenUser.action";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";

const userLogin = async ( {user} ) => {
  try{
    let data = await Axios.post(`/api/auth/login`, {
      user
    });
    return data;
  }catch(error){
    throw error
  }
}

const getLoggedInUser = async () => {
  try{
    let data = await Axios.get('/api/auth/getCurrentUser');
    return data
  }catch(error){
    throw error
  }
}

function* fetchSetCurrentUserRequest(params){
  try{
    const data = yield call(userLogin, params);
    yield put(setCurrentUserSuccess(data));
  }catch(error){
    yield put(fetchError(error));
  }
}

function* fetchGetCurrentUserRequest(){
  try {
    const data = yield call(getLoggedInUser);
    yield put( getCurrentUserSuccess(data));
  } catch (error) { 
   
    yield put(fetchError(error));
  }
}

export function* fetchSetCurrentUser() {
  yield takeEvery(types.SET_CURRENT_USER, fetchSetCurrentUserRequest);
}

export function* fetchGetCurrentUser() {
    yield takeEvery(types.GET_CURRENT_USER, fetchGetCurrentUserRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchSetCurrentUser), fork(fetchGetCurrentUser)])
}