import Axios from "axios";

export const Register = async ( registerData, user ) => {
  try {
    registerData.userId = user && user.userId ? user.userId : null
    const response = await Axios.post('/api/register',
      {
        registerData,
        user
      }
    );
    return response.data
  } catch (error) {
    return error
  }
}

export const Update = async ( profileData, user ) => {
  try {
    profileData.userId = user && user.userId ? user.userId : null
    const response = await Axios.post('/api/updateProfile',
      {
        profileData,
        user
      }
    );
    return response.data
  } catch (error) {
    return error
  }
}

export const checkHandleAvailability = async ( handle, user ) => {
  try {
    const response = await Axios.post(`/api/checkHandle?handle=${handle}`, 
     {
       user
     }
    );
    return response.data
  }catch(error){
    return error
  }
}

export const AccountLinking = async ( account ) => {
  try { 
    const response = await Axios.post("/api/accountLinking",
     account
    )
    return response.data
  }catch(error){
    return error
  }
}

export const updateCreatorPhone = async ( payload ) => {
  try { 
    const response = await Axios.post("/api/updatePhoneNo",
     payload
    )
    return response
  }catch(error){
    return error
  }
}