import Axios from "axios";

export const setAppLanguage = async (lang) => {
  try {
    const response = await Axios.post('/api/setAppLanguage',
      {
        appLanguage: lang
      }
    );
    return response
  } catch (error) {
    return error
  }
}