import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";
import {  getHashtagsSuccess } from "../actions/hashtag.action";

const getHashtagAxios = async (params) => {
    try{
        let data = await Axios.post(`/api/hashtags`, {
            hashtagName : params.hashtagSearch,
            filterHidden: params.filterHidden
        });
        const valueLabelObject =await data.data;
        return {data : valueLabelObject};
      }catch(error){
        throw error;
      }
}


function* fetchLanguageListRequest(params) {
  try {
    const data = yield call(getHashtagAxios,params);
    yield put(getHashtagsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* fetchLanguageList() {
  yield takeEvery(types.GET_HASHTAGS, fetchLanguageListRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchLanguageList)])
}