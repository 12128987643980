import Validator from "validator";

const Validations = {
	required: ( val, err ) => {
		if ( !val ) {
			return err || "Required";
		}
		return "";
	},
	requiredArr: ( val, err ) => {
		if ( !val || !val.length ) {
			return err || "Required";
		}
		return "";
	},
	only5digits: (str, err) => {
		let count = 0;
		for (let ch of str) {
		  if (ch >= "0" && ch <= "9") {
			count++;
		  }
		}
		if ( count > 5 ) {
			return err || "Only 5 digits allowed.";
		}
		return null;
	},
	number: ( val, err ) => {
		const numberReg = new RegExp( "^[0-9]+$" );
		if ( !numberReg.test( val ) ) {
			return err || "Required to be number";
		}
		return null;
	},
	char: ( val, err ) => {
		const charReg = new RegExp( "^[^0-9]+$" );
		if ( !charReg.test( val ) ) {
			return err || "Required to be character"
		}
	},
	dateRangeRequired: ( val, err ) => {
		if ( !val.startDate || !val.endDate ) {
			return err || "Required";
		}
		return "";
	},
	isFloat: ( val, err ) => {
		if ( val && !Validator.isFloat( String( val ) ) ) {
			return err || "Should be in decimal";
		}
		return "";
	},
	isInteger: ( val, err ) => {
		if ( val && !Validator.isInt( String( val ) ) ) {
			return err || "Should be integer";
		}
		return "";
	},
	rteRequired: ( val, err ) => {
		if (
			!val
				.getEditorState()
				.getCurrentContent()
				.hasText()
		) {
			return err || "Required";
		}
		return "";
	},
	email: ( val, err ) => {
		if ( !val || !Validator.isEmail( val.trim() ) ) {
			return err || "Please enter a valid email";
		}
		return "";
	},
	phoneNumber: ( val, err ) => {
		const numberReg = new RegExp( "^[0-9]+$" );
		if ( !val || !numberReg.test( val ) ) {
			return err || "Please enter a valid phone number";
		}
		if ( !val || val.toString().length !== 10 ) {
			return err || "Phone number should be 10 digits only";
		}
		return null;
	},
	profileLink: ( val, err ) => {
		if ( !val || val.length > 200 ) {
			return err || "Social profile link should be within 200 characters";
		}
		return "";
	},
	password: ( val, err ) => {
		if ( !val || val.length > 100 ) {
			return err || "Password should be within 100 characters";
		}
		return "";
  },
  
  max20chars:( val, err) => {
    if ( val && val.length > 20 ) {
			return err || "Expecting 20 or less characters";
		}
		return "";
  },
  min5chars:( val, err) => {
    if ( val && val.length < 5 ) {
			return err || "Expecting 5 or more characters";
		}
		return "";
  },
  max30chars:( val, err) => {
    if ( val && val.length > 30 ) {
			return err || "Expecting 30 or less characters";
		}
		return "";
  },
  noSpecialChar: (val, err) => {
	const checker = /[~@$^()+=|/<>]/;
	if(checker.test(val)) {
	  return err || "'~@$^()+=|/<>' Special Characters are not allowed";
	}
	return "";
  },
	max300chars: ( val, err ) => {
		if ( val && val.length > 300 ) {
			return err || "Expecting 300 or less characters";
		}
		return "";
	},
	max400chars: ( val, err ) => {
		if ( val && val.length > 400 ) {
			return err || "Expecting 300 or less characters";
		}
		return "";
	},
	isJson: (val, err) => {
		try {
			JSON.parse(val)
			return ""
		} catch (error) {
			return err || "Enter valid Json"
		}
	}
};

export default Validations;
