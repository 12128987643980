import * as types from '../../constants/ActionTypes';

export const getDhTags = () => {
  return {
    type: types.GET_DHTAGS
  }
}

export const getDhTagsSuccess = ({ data }) => {
  return {
    type: types.GET_DHTAGS_SUCCESS,
    dhTagsList: data
  }
}
