
export const Users = [
  {
    userId: 1,
    email: "individual.creator@ymail.com",
    permissions: [
      "CREATE_POST",
      "SUBMIT_POST",
      "UPDATE_POST"
    ],
    flowId: "external-without-moderation"
  },
  {
    userId: 2,
    email: "team.admin@ymail.com",
    permissions: [
      "CREATE_POST",
      "SUBMIT_POST",
      "UPDATE_POST",
      "REJECT_POST"
    ],
    flowId: "external-with-moderation"
  },
  {
    userId: 3,
    email: "team.contributor@ymail.com",
    permissions: [
      "CREATE_POST",
      "SUBMIT_FOR_REVIEW",
      "UPDATE_POST"
    ],
    flowId: "external-with-moderation"
  },
  {
    userId: 4,
    email: "dh.publisher@dh.com",
    permissions: [
      "REJECT_POST",
      "PUBLISH_POST"
    ],
    flowId: "external-with-moderation"
  }
]

export const flows = [
  {
    "id": "external-without-moderation",
    "screens": [
      {
        "name": "CREATE_POST",
        "actions": [
          {
            "value": "SAVE",
            "label": "Save",
            "constraints": {
              "states": [
                "START"
              ],
              "permissions": [
                "CREATE_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "CREATED"
                }
              }
            }
          },
          {
            "value": "SUBMIT",
            "label": "Publish",
            "constraints": {
              "states": [
                "START"
              ],
              "permissions": [
                "SUBMIT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
                }
              }
            }
          }
        ]
      },
      {
        "name": "EDIT_POST",
        "actions": [
          {
            "value": "UPDATE",
            "label": "Save",
            "constraints": {
              "states": [
                "CREATED",
                "DRAFT",
                "REJECTED",
                "UNPUBLISHED",
                "PUBLISHED_FAILED"
              ],
              "permissions": [
                "UPDATE_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "DRAFT"
                }
              }
            }
          },
          {
            "value": "SUBMIT",
            "label": "Publish",
            "constraints": {
              "states": [
                "CREATED",
                "DRAFT",
                "REJECTED",
                "UNPUBLISHED",
                "PUBLISHED"
              ],
              "permissions": [
                "SUBMIT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
                }
              }
            }
          },
          {
            "value": "REJECT",
            "label": "Reject",
            "constraints": {
              "states": [
                "CREATED",
                "DRAFT",
                "SUBMITTED_FOR_DHPUBLISH_REVIEW"
              ],
              "permissions": [
                "REJECT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "REJECTED"
                }
              }
            }
          },
          {
            "value": "PUBLISH",
            "label": "Publish",
            "constraints": {
              "states": [
                "SUBMITTED_FOR_DHPUBLISH_REVIEW",
                "PUBLISH_FAILED",
                "PUBLISHED"
              ],
              "permissions": [
                "PUBLISH_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "PUBLISHED"
                }
              }
            }
          },
          {
            "value": "UNPUBLISH",
            "label": "Unpublish",
            "constraints": {
              "states": [
                "PUBLISHED"
              ],
              "permissions": [
                "PUBLISH_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "UNPUBLISHED"
                }
              }
            }
          }
        ]
      }
    ],
    "states": [
      {
        "name": "START",
        "next": [
          {
            "action": "SAVE",
            "state": "CREATED"
          },
          {
            "action": "SUBMIT",
            "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
          }
        ]
      },
      {
        "name": "CREATED",
        "next": [
          {
            "action": "SUBMIT",
            "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
          },
          {
            "action": "UPDATE",
            "state": "DRAFT"
          },
          {
            "action": "REJECT",
            "state": "REJECTED"
          }
        ]
      },
      {
        "name": "DRAFT",
        "next": [
          {
            "action": "UPDATE",
            "state": "DRAFT"
          },
          {
            "action": "SUBMIT",
            "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
          },
          {
            "action": "REJECT",
            "state": "REJECTED"
          }
        ]
      },
      {
        "name": "SUBMITTED_FOR_DHPUBLISH_REVIEW",
        "next": [
          {
            "action": "publish",
            "state": "PUBLISHED"
          },
          {
            "action": "REJECT",
            "state": "REJECTED"
          }
        ]
      },
      {
        "name": "PUBLISHED",
        "next": [
          {
            "action": "UNPUBLISH",
            "state": "UNPUBLISHED"
          },
          {
            "action": "publish",
            "state": "PUBLISHED"
          }
        ]
      },
      {
        "name": "REJECTED",
        "next": [
          {
            "action": "SAVE",
            "state": "DRAFT"
          },
          {
            "action": "publish",
            "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
          }
        ]
      }
    ]
  },
  {
    "id": "external-with-moderation",
    "screens": [
      {
        "name": "CREATE_POST",
        "actions": [
          {
            "value": "SAVE",
            "label": "Save",
            "constraints": {
              "states": [
                "START"
              ],
              "permissions": [
                "CREATE_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "CREATED"
                }
              }
            }
          },
          {
            "value": "SUBMIT_FOR_ACCOUNT_REVIEW",
            "label": "Publish",
            "constraints": {
              "states": [
                "START"
              ],
              "permissions": [
                "SUBMIT_FOR_REVIEW"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "SUBMITTED_FOR_ACCOUNT_REVIEW"
                }
              }
            }
          }
        ]
      },
      {
        "name": "EDIT_POST",
        "actions": [
          {
            "value": "SAVE",
            "label": "Save",
            "constraints": {
              "states": [
                "START",
                "REJECTED"
              ],
              "permissions": [
                "CREATE_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "CREATED"
                }
              }
            }
          },
          {
            "value": "UPDATE",
            "label": "Save",
            "constraints": {
              "states": [
                "CREATED",
                "DRAFT",
                "REJECTED",
                "UNPUBLISHED",
                "PUBLISHED_FAIL"
              ],
              "permissions": [
                "UPDATE_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "DRAFT"
                }
              }
            }
          },
          {
            "value": "SUBMIT",
            "label": "Publish",
            "constraints": {
              "states": [
                "SUBMITTED_FOR_ACCOUNT_REVIEW"
              ],
              "permissions": [
                "SUBMIT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
                }
              }
            }
          },
          {
            "value": "SUBMIT_FOR_ACCOUNT_REVIEW",
            "label": "Publish",
            "constraints": {
              "states": [
                "START",
                "CREATED",
                "DRAFT",
                "REJECTED",
                "UNPUBLISHED",
                "PUBLISHED"
              ],
              "permissions": [
                "SUBMIT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "SUBMITTED_FOR_ACCOUNT_REVIEW"
                }
              }
            }
          },
          {
            "value": "PUBLISH",
            "label": "Publish",
            "constraints": {
              "states": [
                "SUBMITTED_FOR_DHPUBLISH_REVIEW",
                "PUBLISH_FAILED",
                "PUBLISHED"
              ],
              "permissions": [
                "PUBLISH_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "PUBLISHED"
                }
              }
            }
          },
          {
            "value": "REJECT",
            "label": "Reject",
            "constraints": {
              "states": [
                "CREATED",
                "DRAFT",
                "SUBMITTED_FOR_DHPUBLISH_REVIEW",
                "SUBMITTED_FOR_ACCOUNT_REVIEW"
              ],
              "permissions": [
                "REJECT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "REJECTED"
                }
              }
            }
          },
          {
            "value": "UNPUBLISH",
            "label": "Unpublish",
            "constraints": {
              "states": [
                "PUBLISHED"
              ],
              "permissions": [
                "REJECT_POST"
              ]
            },
            "next": {
              "screen": {
                "name": "LIST_POSTS",
                "params": {
                  "state": "UNPUBLISHED"
                }
              }
            }
          }
        ]
      }
    ],
    "states": [
      {
        "name": "START",
        "next": [
          {
            "action": "save",
            "state": {
              "success": "CREATED",
              "failure": "START"
            }
          },
          {
            "action": "submit",
            "state": {
              "success": "SUBMITTED_FOR_DHPUBLISH_REVIEW",
              "failure": "START"
            }
          },
          {
            "action": "submit_for_account_review",
            "state": {
              "success": "SUBMITTED_FOR_ACCOUNT_REVIEW",
              "failure": "START"
            }
          }
        ]
      },
      {
        "name": "CREATED",
        "next": [
          {
            "action": "submit_for_account_review",
            "state": {
              "success": "SUBMITTED_FOR_ACCOUNT_REVIEW",
              "failure": "CREATED"
            }
          },
          {
            "action": "submit",
            "state": {
              "success": "SUBMITTED_FOR_DHPUBLISH_REVIEW",
              "failure": "CREATED"
            }
          },
          {
            "action": "update",
            "state": {
              "success": "DRAFT",
              "failure": "CREATED"
            }
          },
          {
            "action": "reject",
            "state": {
              "success": "REJECTED",
              "failure": "CREATED"
            }
          }
        ]
      },
      {
        "name": "DRAFT",
        "next": [
          {
            "action": "submit_for_account_review",
            "state": {
              "success": "SUBMITTED_FOR_ACCOUNT_REVIEW",
              "failure": "DRAFT"
            }
          },
          {
            "action": "submit",
            "state": {
              "success": "SUBMITTED_FOR_DHPUBLISH_REVIEW",
              "failure": "DRAFT"
            }
          },
          {
            "action": "update",
            "state": {
              "success": "DRAFT",
              "failure": "DRAFT"
            }
          },
          {
            "action": "reject",
            "state": {
              "success": "REJECTED",
              "failure": "DRAFT"
            }
          }
        ]
      },
      {
        "name": "SUBMITTED_FOR_ACCOUNT_REVIEW",
        "next": [
          {
            "action": "submit",
            "state": {
              "success": "SUBMITTED_FOR_DHPUBLISH_REVIEW",
              "failure": "SUBMITTED_FOR_ACCOUNT_REVIEW"
            }
          },
          {
            "action": "reject",
            "state": {
              "success": "REJECTED",
              "failure": "SUBMITTED_FOR_ACCOUNT_REVIEW"
            }
          }
        ]
      },
      {
        "name": "SUBMITTED_FOR_DHPUBLISH_REVIEW",
        "next": [
          {
            "action": "publish",
            "state": {
              "success": "PUBLISHED",
              "failure": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
            }
          },
          {
            "action": "reject",
            "state": {
              "waiting-for-approval": "",
              "success": "REJECTED",
              "failure": "SUBMITTED_FOR_DHPUBLISH_REVIEW"
            }
          }
        ]
      },
      {
        "name": "PUBLISHED",
        "next": [
          {
            "action": "unpublish",
            "state": {
              "success": "UNPUBLISHED",
              "failure": "PUBLISHED"
            }
          },
          {
            "action": "publish",
            "state": {
              "success": "PUBLISHED",
              "failure": "PUBLISHE_FAILED"
            }
          },
          {
            "action": "update",
            "state": {
              "success": "DRAFT",
              "failure": "PUBLISHED"
            }
          }
        ]
      },
      {
        "name": "REJECTED",
        "next": [
          {
            "action": "save",
            "state": {
              "success": "DRAFT",
              "failure": "REJECTED"
            }
          }
        ]
      },
      {
        "name": "PUBLISH_FAILED",
        "next": [
          {
            "action": "update",
            "state": {
              "success": "DRAFT",
              "failure": "PUBLISH_FAILED"
            }
          }
        ]
      }
    ]
  }
]

export const oboardingConstraints =
  [
    {
      "state": {
        "profile-submission": "false",
        "phone-verification": "approved"
      },
      "screen": "profile-submission"
    },
    {
      "state": {
        "profile-submission": "true",
        "work-approval": "pending"
      },
      "screen": "work-approval-pending"
    },
    {
      "state": {
        "profile-submission": "true",
        "work-approval": "rejected"
      },
      "screen": "waiting-rejected-screen"
    },
  ]

export const premiumUserFeature = [
  {
    "state": {
      "bank-details-submission": "false",
      "onboarding-complete": "true"
    },
    "screen": "bank-details-submission"
  },
  {
    "state": {
      "bank-details-approval": "pending",
      "bank-details-submission": "true",
      "onboarding-complete": "true"
    },
    "screen": "bank-details-approval-pending"
  }
]

export const namedRoutes = {
  "registration": "/app/post/list",
  "profile-submission": "/profile",
  "account-linking": "/merge-accounts",
  "work-approval-pending": "/pending",
  "phone-submission": "/verify-phone",
  "phone-verification": "/verify-phone",
  "waiting-for-approval": "/pending",
  "waiting-rejected-screen": "/profile-rejected",
  "post-listing": "/app/post/list?state=PUBLISHED",
  "authentication-screen": "/token-login",
  "bankdetails-submission-screen": "/bank-details",
  "dashboard": "/app/post/list?state=PUBLISHED",
  "login-external": "/login",
  "login-internal": "/login/internal",
  "login-agency": "/login/agency"
}