import * as types from '../../constants/ActionTypes';

export const setCurrentUser = ( user ) => {
  return {
    type: types.SET_CURRENT_USER,
    user
  }
}

export const setCurrentUserSuccess = ({data}) => {
  return {
    type: types.SET_CURRENT_USER_SUCCESS,
    currentUser: data
  }
}

export const getCurrentUser = () => {
  return {
    type: types.GET_CURRENT_USER
  }
}

export const getCurrentUserSuccess = ({data}) => {
  return {
    type: types.GET_CURRENT_USER_SUCCESS,
    currentUser: data
  }
}



// export const logoutUser = () => {
//   return {
//     type: types.LOGOUT_USER
//   }
// }

// export const logoutUserSuccess = () => {
//   return {
//     type: types.LOGOUT_USER_SUCCESS
//   }
// }