import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from 'i18next-xhr-backend';
// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

import translation from "./locales/index.json"

const resources = {
	en: {
		translation: translation.English
	}, 
	hi: {
		translation: translation.Hindi
	},
	ta: {
		translation: translation.Tamil
	},
	te: {
		translation: translation.Telugu
	},
	ml: {
		translation: translation.Malayalam
	},
	bn: {
		translation: translation.Bengali
	},
	mr: {
		translation: translation.Marathi
	},
	kn: {
		translation: translation.Kannada
	},
	gu: {
		translation: translation.Gujarati
	},
	pa: {
		translation: translation.Punjabi
	},
	or: {
		translation: translation.Oriya
	},
	ur: {
		translation: translation.Urdu
	}
}
i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
//   .use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// .use( LanguageDetector )
// pass the i18n instance to react-i18next.
	.use( initReactI18next )
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
	.init( {
		returnEmptyString:false,
		resources,
		ns: { namespaces: [ "translation" ], defaultNs: "translation" },
		fallbackLng:"en",
		keySeparator: false, // we do not use keys in form messages.welcome
		lng:"en",
		interpolation: {
			escapeValue: false // react already safes from xss
		}

	} );

export default i18n;