import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Post from "./post.reducer";
import Config from "./config.reducer"
import Agency from "./agency.reducer";
import Common from "./common.reducer";
import Creator from "./creator.reducer";
import Account from "./account.reducer";
import Source from "./source.reducer";
import CurrentUser from "./currentUser.reducer";
import Upload from "./upload.reducer";
import Language from "./language.reducer";
import Location from "./location.reducer";
import Hashtag from "./hashtag.reducer";
import DhTags from "./dhTags.reducer";
import Analytics from "./analytics.reducer";
import Profile from "./profile.reducer";
import WorkApproval from "./workApproval.reducer";
import BankDetails from "./bankDetails.reducer";
import IsOgcToIcc from "./isOgcToIcc.reducer";
import Invoice from "./invoices.reducer";

const reducers = combineReducers({
  routing: routerReducer,
  post: Post,
  agency: Agency,
  creator: Creator,
  account: Account,
  source: Source,
  common: Common,
  config: Config,
  currentUser: CurrentUser,
  upload: Upload,
  language: Language,
  location: Location,
  hashtag: Hashtag,
  dhTags: DhTags,
  analytics: Analytics,
  profile: Profile,
  workApproval: WorkApproval,
  bankDetails: BankDetails,
  isOgcToIcc: IsOgcToIcc,
  invoice: Invoice,
});

export default reducers;
