// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const SET_UTM_PARAMS = "SET_UTM_PARAMS";
export const SET_POST_TYPE = "SET_POST_TYPE";
export const SET_REGION = "SET_REGION";

//Configs 
export const GET_CONFIG_DATA = 'GET_CONFIG_DATA';
export const GET_CONFIG_DATA_SUCCESS = 'GET_CONFIG_DATA_SUCCESS';

export const GET_EDITOR_API_KEY = 'GET_EDITOR_API_KEY';
export const GET_EDITOR_API_KEY_SUCCESS ='GET_EDITOR_API_KEY_SUCCESS';

export const GET_COMM_CONFIG_DATA  = 'GET_COMM_CONFIG_DATA';
export const GET_COMM_CONFIG_DATA_SUCCESS = 'GET_COMM_CONFIG_DATA_SUCCESS';

//File Upload
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'

//Post depenedencies
export const GET_POST_DEPENDENCIES = "GET_POST_DEPENDENCIES"

//Language 
export const GET_LANGUAGES = "GET_LANGUAGES"
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS"

//location
export const GET_LOCATIONS = "GET_LOCATIONS"
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS"

//hashtag
export const GET_HASHTAGS = "GET_HASHTAGS"
export const GET_HASHTAGS_SUCCESS = "GET_HASHTAGS_SUCCESS"

//DHTags
export const GET_DHTAGS = "GET_DHTAGS"
export const GET_DHTAGS_SUCCESS = "GET_DHTAGS_SUCCESS"

//Post Upsert
export const UPSERT_POST = "UPSERT_POST"
export const UPSERT_POST_SUCCESS = "UPSERT_POST_SUCCESS"

export const UPSERT_FEATURED_POSTS = "UPSERT_FEATURED_POSTS"
export const UPSERT_FEATURED_POSTS_SUCCESS = "UPSERT_FEATURED_POSTS_SUCCESS"

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const RESET_ERROR = 'RESET_ERROR';

//Reset State
export const RESET_POST_STATE = "RESET_POST_STATE";


//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';


//Post


export const GET_FEATURED_POST = 'GET_FEATURED_POST';
export const GET_FEATURED_POST_SUCCESS = 'GET_FEATURED_POST_SUCCESS';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';

export const SET_POST_LIST_DATA = 'SET_POST_LIST_DATA';

export const GET_POST_LIST_BY_FILTER_SUCCESS = 'GET_POST_LIST_BY_FILTER_SUCCESS';
export const GET_POST_LIST_BY_FILTER = 'GET_POST_LIST_BY_FILTER';

export const GET_OGC_POST_LIST_BY_FILTER_SUCCESS = 'GET_OGC_POST_LIST_BY_FILTER_SUCCESS';
export const GET_OGC_POST_LIST_BY_FILTER = 'GET_OGC_POST_LIST_BY_FILTER';
//Analytics

export const GET_QUICK_ANALYTICS = 'GET_QUICK_ANALYTICS';
export const GET_QUICK_ANALYTICS_SUCCESS = 'GET_QUICK_ANALYTICS_SUCCESS';

//Profile

export const INVOICE_ACCEPTED = 'INVOICE_ACCEPTED';
export const GET_PAYMENTS_LIST = 'GET_PAYMENTS_LIST';
export const GET_PAYMENTS_LIST_SUCCESS = 'GET_PAYMENTS_LIST_SUCCESS';
export const INVOICE_ACCEPTED_SUCCESS = 'INVOICE_ACCEPTED_SUCCESS';

export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const GET_PROFILE_DETAILS_SUCCESS = 'GET_PROFILE_DETAILS_SUCCESS';


export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_SUCCESS = 'SET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const SET_CONFLICT_ACCOUNTS = 'SET_CONFLICT_ACCOUNTS';

export const TRIGGER_POST_DATA_FETCH = 'TRIGGER_POST_DATA_FETCH';
export const GET_POST_BY_ID = 'GET_POST_BY_ID'
export const GET_POST_BY_ID_SUCCESS = 'GET_POST_BY_ID_SUCCESS'
export const GET_OGC_POST_BY_ID = 'GET_OGC_POST_BY_ID'
export const GET_OGC_POST_BY_ID_SUCCESS = 'GET_OGC_POST_BY_ID_SUCCESS'
export const GET_POPST_BY_ID_ERROR = 'GET_POST_BY_ID_ERROR'

//Agency
export const GET_AGENCY_LIST_BY_FILTER = 'GET_AGENCY_LIST_BY_FILTER';
export const GET_AGENCY_LIST_BY_FILTER_SUCCESS = 'GET_AGENCY_LIST_BY_FILTER_SUCCESS';
export const UPSERT_AGENCY = "UPSERT_AGENCY";
export const UPSERT_AGENCY_SUCCESS = 'UPSERT_AGENCY_SUCCESS';
export const RESET_AGENCY_STATE = "RESET_AGENCY_STATE";

//Creator
export const GET_CREATOR_LIST_BY_FILTER = 'GET_CREATOR_LIST_BY_FILTER';
export const GET_CREATOR_LIST_BY_FILTER_SUCCESS = 'GET_CREATOR_LIST_BY_FILTER_SUCCESS';
export const CREATE_CREATOR = "CREATE_CREATOR";
export const CREATE_CREATOR_SUCCESS = "CREATE_CREATOR_SUCCESS";
export const UPDATE_CREATOR = 'UPDATE_CREATOR';
export const UPDATE_CREATOR_SUCCESS = 'UPDATE_CREATOR_SUCCESS';
export const BLOCK_CREATOR = 'BLOCK_CREATOR';
export const BLOCK_CREATOR_SUCCESS = 'BLOCK_CREATOR_SUCCESS';
export const RESET_CREATOR_STATE = "RESET_CREATOR_STATE";

//Accounts
export const GET_ACCOUNT_LIST_BY_FILTER_SUCCESS = 'GET_ACCOUNT_LIST_BY_FILTER_SUCCESS';
export const GET_ACCOUNT_LIST_BY_FILTER = 'GET_ACCOUNT_LIST_BY_FILTER';
export const UPSERT_ACCOUNT = "UPSERT_ACCOUNT";
export const UPSERT_ACCOUNT_SUCCESS = "UPSERT_ACCOUNT_SUCCESS";
export const RESET_ACCOUNT_STATE = "RESET_ACCOUNT_STATE";
//Source
export const GET_SOURCE_LIST_BY_FILTER = 'GET_SOURCE_LIST_BY_FILTER';
export const GET_SOURCE_LIST_BY_FILTER_SUCCESS = 'GET_SOURCE_LIST_BY_FILTER_SUCCESS';
export const GET_SEARCH_SOURCE_FILTER = 'GET_SEARCH_SOURCE_FILTER';
export const GET_SEARCH_SOURCE_FILTER_SUCCESS = "GET_SEARCH_SOURCE_FILTER_SUCCESS"
export const UPSERT_SOURCE = "UPSERT_SOURCE";
export const UPSERT_SOURCE_SUCCESS = "UPSERT_SOURCE_SUCCESS";
export const RESET_SOURCE_STATE = "RESET_SOURCE_STATE";
export const CONVERT_OGC_TO_UGC = "CONVERT_OGC_TO_UGC"

//Work Approval

export const GET_WORK_APPROVAL_LIST_BY_FILTER = 'GET_WORK_APPROVAL_LIST_BY_FILTER';
export const GET_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS = 'GET_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS';
export const UPDATE_WORK_APPROVAL_DETAILS = 'UPDATE_WORK_APPROVAL_DETAILS';
export const UPDATE_WORK_APPROVAL_DETAILS_SUCCESS = 'UPDATE_WORK_APPROVAL_DETAILS_SUCCESS';
export const RESET_WORK_APPROVAL_STATE = "RESET_WORK_APPROVAL_STATE";
export const VIEW_WORK_APPROVAL_LIST_BY_FILTER = 'VIEW_WORK_APPROVAL_LIST_BY_FILTER';
export const VIEW_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS = 'VIEW_WORK_APPROVAL_LIST_BY_FILTER_SUCCESS';

// INVOICES

export const GET_INVOICE_LIST = 'GET_INVOICE_LIST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const RESET_INVOICE_STATE = 'RESET_INVOICE_STATE';
export const GET_ZIP_INVOICES = 'GET_ZIP_INVOICES';
export const GET_ZIP_INVOICES_SUCCESS = 'GET_ZIP_INVOICES_SUCCESS';

//Mobile Detector
export const MOBILE_DETECTOR = "MOBILE_DETECTOR"

//Bank Details
export const GET_BANK_DETAILS_LIST = "GET_BANK_DETAILS_LIST";
export const GET_BANK_DETAILS_LIST_SUCCESS = "GET_BANK_DETAILS_LIST_SUCCESS";
export const APPROVE_BANK_DETAILS = "APPROVE_BANK_DETAILS";
export const APPROVE_BANK_DETAILS_SUCCESS = "APPROVE_BANK_DETAILS_SUCCESS";

//
export const IS_OGC_TO_ICC = "IS_OGC_TO_ICC";