import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";

import { notification as Notification, message } from "antd";
import _Get from "lodash/get";
import { getSourceListByFilter, getSourceListByFilterSuccess, upsertSourceSuccess, getSourceSearchFilterSuccess } from "../actions/source.action"
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";

const getSourceByFilter = async (params) => {
  try {
    let data = await Axios.post(`/api/source/getSourceByFilter`,
      {
        pageNumber: params.pageNumber,
        filters: params.filters
      });
    return data;
  } catch (error) {
    throw error;
  }
}

const getSourceSearchFilter = async (params) => {
  return await Axios.post(`/api/source/searchFilter`, { queryParams: params.queryParams });
}

const convertOgcToUgc = async (params) => {
  const data = await Axios.post(`/api/source/convertOgcToUgc`, { payload: params.payload });
  if (data.data.status) {
    Notification.success({
      message: data.data.message
    })
  } else {
    Notification.error({
      message: _Get(data, "data.message") || "Something went wrong !!"
    })
  }
  return data
}

const upsertSource = async (params) => {

  try {
    let data = await Axios.post(`/api/source/upsert`, { sourceData: params.sourceUpsert });
    if (data.status === 200) {
      Notification.success({
        message: "Success"
      })
    } else {
      Notification.error({
        message: _Get(data, "data.message") || "Something went wrong !!"
      })
    }
    return data;
  } catch (error) {
    throw error;
  }
}

function* upsertSourceRequest(params) {
  try {
    const data = yield call(upsertSource, params)
    yield put(upsertSourceSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchSourceListRequest(params) {
  try {
    const data = yield call(getSourceByFilter, params)

    yield put(getSourceListByFilterSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getSourceSearchFilterRequest(params) {
  try {
    const data = yield call(getSourceSearchFilter, params)
    yield put(getSourceSearchFilterSuccess(data.data.rows))
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* convertOgcToUgcRequest(params) {
  try {
    const data = yield call(convertOgcToUgc, params)
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchSourceList() {
  yield takeEvery(types.GET_SOURCE_LIST_BY_FILTER, fetchSourceListRequest);
}
export function* upsertSourceGeneratorMap() {
  yield takeEvery(types.UPSERT_SOURCE, upsertSourceRequest);
}
export function* fetchSourceSearchFilter() {
  yield takeEvery(types.GET_SEARCH_SOURCE_FILTER, getSourceSearchFilterRequest);
}
export function* upsertConvertOgcToUgc() {
  yield takeEvery(types.CONVERT_OGC_TO_UGC, convertOgcToUgcRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchSourceList), fork(upsertSourceGeneratorMap), fork(fetchSourceSearchFilter), fork(upsertConvertOgcToUgc)])
}
