import * as types from '../../constants/ActionTypes';

const initialState = {
  bankDetailsList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_BANK_DETAILS_LIST:
      return {
        ...state,
        loading: true,
      }
    case types.GET_BANK_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bankDetailsList: action.data,
      }
    default:
      return state
  }
}
