import * as types from '../../constants/ActionTypes';
import _ from "lodash";

const initialState = {
  dhTagsList: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_DHTAGS:
      return {
        ...state,
        dhTagsList: state.dhTagsList
      }
    case types.GET_DHTAGS_SUCCESS:
      return {
        ...state,
        dhTagsList: action.dhTagsList
      }
    default:
      return state
    }
  }