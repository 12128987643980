import * as types from '../../constants/ActionTypes';
import _ from "lodash";

const initialState = {
  isOgcToIcc: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.IS_OGC_TO_ICC:
      return {
        ...state,
        isOgcToIcc: true
      }
    default:
      return state
  }
}