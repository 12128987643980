import React, { useState, Component } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Button } from "antd";

import "./styles.css"

class OnBoardingModalContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showDefaultContent: true
    }
  }



  _startOnBoarding = () => {
    this.props.toggleOnBoardingModal(false)
    this.props.closeDrawer()
    this.props.history.push("/app/profile")
  }

  _skipOnBoarding = () => {
    this.props.toggleOnBoardingModal(false)
    this.props.closeDrawer()
  }

  _onBoardingActionButtons = () => {
    return (
      <div>
        <div className="d-flex justify-content-center">
          <Button shape={"round"} onClick={this._skipOnBoarding} >
            {this.props.t("MODAL_SKIP")}
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button style={{ marginTop: "5px" }} type="primary" shape={"round"} onClick={this._startOnBoarding}>
            {this.props.t("MODAL_CREATE_PROFILE")}
          </Button>
        </div>
      </div>
    )
  }

  _getDefaultContent = () => {
    return (
      <>
        <div>
          <h1
            style={{
              padding: "3%",
              fontWeight: "500"
            }}
          >
            {this.props.t("MODAL_HEADING")}
          </h1>
        </div>
        <div
          style={{
            padding: "3%"
          }}
        >
          {this.props.t("MODAL_TEXT")}
        </div>
        {
          this._onBoardingActionButtons()
        }
      </>
    )
  }

  render() {
    return (
      <Modal
        visible={this.props.showOnboardingModal}
        footer={null}
        width={600}
        onCancel={() => {
          this.props.toggleOnBoardingModal(false)
        }}
      >
        {
          this._getDefaultContent()
        }
      </Modal>
    )
  }
}

export default withTranslation()(OnBoardingModalContainer);


