import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import Login from "../../containers/Login/external";
const LoginRoutes = ({match}) => (  
  <Switch>
    <Route path={"/login/internal"} component={asyncComponent(() => import('../../containers/Login/internal'))}/>
    <Route path={"/login/mobile"} component={asyncComponent(()=>import("../../containers/Login/mobile"))}/>
    <Route path={"/login"} component={Login}/>
    <Route render={() => <Redirect to={"/login"}/>} />
  </Switch>  
);   

export default LoginRoutes