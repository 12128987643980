import { all } from "redux-saga/effects";
import PostSaga from "./post.saga";
import ConfigSaga from "./config.saga";
import AgencySaga from "./agency.saga";
import CreatorSaga from "./creator.saga";
import AccountSaga from "./account.saga";
import SourceSaga from "./source.saga";
import CurrentUserSaga from "./currentUser.saga";
import UploadSaga from "./upload.saga";
import LanguageSaga from "./languages.saga";
import LocationSaga from "./location.saga";
import HashtagSaga from "./hashtag.saga";
import DhTags from "./dhTags.saga";
import AnalyticsSaga from "./analytics.saga";
import ProfileSage from "./profile.saga";
import WorkApprovalSaga from "./workApproval.saga";
import PostDependenciesSaga from "./postDependencies.saga"
import BankDetailsSaga from "./bankDetails.saga"
import InvoiceSaga from "./invoices.saga"
export default function* rootSaga(getState) {
  yield all([
    PostSaga(),
    AgencySaga(),
    ConfigSaga(),
    CreatorSaga(),
    AccountSaga(),
    SourceSaga(),
    CurrentUserSaga(),
    UploadSaga(),
    LanguageSaga(),
    LocationSaga(),
    HashtagSaga(),
    DhTags(),
    AnalyticsSaga(),
    ProfileSage(),
    WorkApprovalSaga(),
    PostDependenciesSaga(),
    BankDetailsSaga(),
    InvoiceSaga(),
  ]);
}
