import * as types from '../../constants/ActionTypes';
import _ from "lodash";

const initialState = {
  languageList: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_LANGUAGES:
      return {
        ...state,
        languageList: state.languageList
      }
    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languageList: action.languageList
      }
    default:
      return state
    }
  }