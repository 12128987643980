import * as types from '../../constants/ActionTypes';

// export const getAccountListByFilter = () => {
//   return {
//     type: types.GET_ACCOUNT_LIST_BY_FILTER
//   }
// }
export const getAccountListByFilter = (filters) => {
  return {
    type: types.GET_ACCOUNT_LIST_BY_FILTER,
    filters
  }
}

export const getAccountListByFilterSuccess = ({ data }) => {
  return {
    type: types.GET_ACCOUNT_LIST_BY_FILTER_SUCCESS,
    accountList: data
  }
}
export const upsertAccount =({data, filters})=>{
  return{
    type:types.UPSERT_ACCOUNT,
    accountUpsert:data,
    filters: filters
  }
}
export const upsertAccountSuccess =({data})=>{
  return{
    type:types.UPSERT_ACCOUNT_SUCCESS,
    accountUpsert:data
  }
}
  export const resetState = () => {
    return {
      type: types.RESET_ACCOUNT_STATE
    }
  }
