import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";


import {
  getLocationsSuccess
} from "../actions/location.action";

const getlocationsAxios = async () => {
  try {
    let data = await Axios.get(`/api/locations`, {
      userId: 12
    });

    let valueLabelObject = []
    data.data.map(item => {
      valueLabelObject.push({ label: item.locationName, value: item.locationId })
      item.cities.map(city => {
        let cityObject = {
          label: city.locationName + ` ( ${item.stateName} ) `,
          value: city.locationId
        }
        valueLabelObject.push(cityObject)
      })
    })
    return { data: valueLabelObject };
  } catch (error) {
    throw error;
  }
}


function* fetchLanguageListRequest(params) {
  try {
    const data = yield call(getlocationsAxios);
    yield put(getLocationsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* fetchLanguageList() {
  yield takeEvery(types.GET_LOCATIONS, fetchLanguageListRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchLanguageList)])
}