import * as types from '../../constants/ActionTypes';

const initialState = {
  agencyList:[], 
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    
    case types.GET_AGENCY_LIST_BY_FILTER:
      return {
        ...state,
        loading: true,
        agencyList: state.agencyList
      }
    case types.GET_AGENCY_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyList: action.agencyList,
      }
    case types.UPSERT_AGENCY:
      return{
          ...state,
          agencyUpsert:state.agencyUpsert,
          loading:true
        }
    case types.UPSERT_AGENCY_SUCCESS:
      return{
         ...state,
         loading: false,
          agencyUpsert:action.agencyUpsert
        }
    case types.RESET_AGENCY_STATE:
        return {
          ...state,
          agencyList: []
          }
        
    default:
      return state
  }
}
