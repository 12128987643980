import * as types from '../../constants/ActionTypes';

export const getInvoiceList = (params) => {
  return {
    type: types.GET_INVOICE_LIST,
    params
  }
}

export const getInvoiceListSuccess = ({ data }) => {
  return {
    type: types.GET_INVOICE_LIST_SUCCESS,
    invoiceList: data
  }
}

export const getZipInvoices = (params) => {
    return {
        type: types.GET_ZIP_INVOICES,
        params
      }
}

export const getZipInvoicesSuccess = ({ data }) => {
    return {
      type: types.GET_ZIP_INVOICES_SUCCESS,
      zipInvoice: data
    }
  }

export const resetState = () => {
    return {
      type: types.RESET_INVOICE_STATE
    }
  }