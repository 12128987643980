import React from "react"
import ErrorBoundComponent from "../../components/ErrorBoundComponent";
import { connect } from "react-redux";
import _ from "lodash";

import { Redirect, Route, Switch } from "react-router-dom";
import { namedRoutes } from "../../containers/App/flow";
import OnboardingLayout from "../../components/OnBoardingLayout/onboardingLayout"
import PhoneVerification from "../../containers/OnboardingFlows/OnboardingForms/phoneVerification";
import AccountLinking from "../../containers/OnboardingFlows/OnboardingForms/accountLinking";
import { Card } from "antd";
import WorkApprovalReject from "../../containers/OnboardingFlows/OnboardingForms/workApprovalReject";


class OnboardingRoutes extends ErrorBoundComponent {
  _render = () => {
    const isMobile = _.get(this.props, "isMobile")
    const nextScreen = _.get(this.props, "nextScreen")
    const accountOptions = _.get(this.props, "selectAccount")
    const userId = (_.get(this.props, "currentUser.userId"))
    return (
      <Switch>
        <OnboardingLayout showLogout={true} {...this.props}>
          <div style={{ display: "flex", marginTop: "3rem", alignItems: "center", justifyContent: "center" }} className={isMobile ? "" : "section"}>
            <Card style={{width:`${this.props.isMobile ? "20rem":"30rem"}`}}>
              <Route
                path="/verify-phone"
                exact
                render={() => {
                  return (
                    <PhoneVerification
                      userId={userId}
                      sendOtpAction="submit-phone"
                      verifyOtpAction="verify-phone"
                    />
                  )
                }}
              />
              <Route
                path="/merge-accounts"
                exact
                render={() => {
                  return (
                    <AccountLinking
                      userId={userId}
                      isMobile={isMobile}
                    />
                  )
                }}
              />
              <Route
                path="/profile-rejected"
                exact
                render={()=>{
                  return (
                    <WorkApprovalReject/>
                  )
                }}
              />
              <Route render={() => <Redirect to={namedRoutes[nextScreen]} />} />
            </Card>
          </div>
        </OnboardingLayout>
      </Switch>
    )
  }
}

function mapStateToProps(store) {

  return {
  }
}

export default connect()(OnboardingRoutes)