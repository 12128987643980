import * as types from '../../constants/ActionTypes';

export const getAgencyListByFilter = () => {
  return {
    type: types.GET_AGENCY_LIST_BY_FILTER
  }
}

export const getAgencyListByFilterSuccess = ({ data }) => {
  return {
    type: types.GET_AGENCY_LIST_BY_FILTER_SUCCESS,
    agencyList: data
  }
}

export const upsertAgency =({data})=>{
  return{
    type:types.UPSERT_AGENCY,
    agencyUpsert:data
  }
}
export const upsertAgencySuccess =({data})=>{
  return{
    type:types.UPSERT_AGENCY_SUCCESS,
    agencyUpsert:data
  }
}
export const resetState = () => {
  return {
    type: types.RESET_AGENCY_STATE
  }
}
