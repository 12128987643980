import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";

import {
  getCreatorListByFilter,
  getCreatorListByFilterSuccess,
  createCreatorSuccess,
  updateCreatorSuccess,
  blockCreatorSuccess
} from "../actions/creator.action";
import { notification as Notification, message } from "antd";
import _Get from "lodash/get";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";

const getCreatorByFilter = async (params) => {
  try {
    let data = await Axios.post(`/api/creator/list`, {
      pageNumber: params.pageNumber,
      filters: params.filters
    });
    return data;
  } catch (error) {
    throw error;
  }
}
const createCreator = async (params) => {
  try {
    let data = await Axios.post(`/api/creator/create`, { creatorData: params.creatorCreate });
    if (data.status === 200) {
      Notification.success({
        message: "Success"
      })
    } else {
      Notification.error({
        message: _Get(data, "data.message") || "Something went wrong !!"
      })
    }
    return data;
  } catch (error) {
    throw error;
  }
}

const blockCreator = async (params) => {
  try {
    let data = await Axios.post(`/api/creator/block`, {
      creatorUserId: params.creatorUserId,
      userId: params.userId,
      action: params.modifiedAction
    });
    if (data.status === 200) {
      Notification.success({
        message: "Success"
      })
    } else {
      Notification.error({
        message: _Get(data, "data.message") || "Something went wrong !!"
      })
    }
    return data;
  } catch (error) {
    throw error;
  }
}

const updateCreator = async (params) => {
  try {
    let data = await Axios.post(`/api/creator/update`, { creatorData: params.creatorUpdate });
    if (data.status === 200) {
      Notification.success({
        message: "Success"
      })
    } else {
      Notification.error({
        message: _Get(data, "data.message") || "Something went wrong !!"
      })
    }
    return data;
  } catch (error) {
    throw error;
  }
}

function* createCreatorRequest(params) {
  try {
    const data = yield call(createCreator, params)
    yield put(createCreatorSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* blockCreatorRequest(params) {
  try {
    const data = yield call(blockCreator, params)
    yield put(blockCreatorSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* updateCreatorRequest(params) {
  try {
    const data = yield call(updateCreator, params)
    yield put(updateCreatorSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}


function* fetchCreatorListRequest(params) {
  try {
    const data = yield call(getCreatorByFilter, params)
    yield put(getCreatorListByFilterSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchCreatorList() {
  yield takeEvery(types.GET_CREATOR_LIST_BY_FILTER, fetchCreatorListRequest);
}

export function* createCreatorGeneratorMap() {
  yield takeEvery(types.CREATE_CREATOR, createCreatorRequest);
}
export function* blockCreatorGeneratorMap() {
  yield takeEvery(types.BLOCK_CREATOR, blockCreatorRequest);
}
export function* updateCreatorGeneratorMap() {
  yield takeEvery(types.UPDATE_CREATOR, updateCreatorRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchCreatorList), fork(createCreatorGeneratorMap),
  fork(blockCreatorGeneratorMap),
  fork(updateCreatorGeneratorMap)])
}