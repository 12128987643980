import React from "react";
import { connect } from "react-redux";
import { Layout, Avatar , Icon } from 'antd';
import _ from "lodash";
import BaseLogo from "../../assets/images/base_logo.jpg";
import supportedLanguages from "../../locales/supportedLanguages";
import {logoutUser} from "../../clientServices/auth";
import {getCurrentUser} from "../../appRedux/actions/currenUser.action";
import LoginHeader from "../AppModuleHeader/loginModuleHeader";
import "./styles.css";

const { Header, Content } = Layout;

const _onLanguageChange = ( value ) => {
}

const _logout = async ( props ) => {
   await logoutUser();
   props.getCurrentUser();
}

const _getCurrentAppLanguage = ( langauge ) => {
  return _.get( _.filter( supportedLanguages, ( { value } ) => value === langauge ), "0.label" )
}

const LoginLayout = ( props ) => {
  return (
    <Layout >
      <LoginHeader 
        logout = { props.logout }
        showLogout={ props.showLogout }
        appLanguage={props.appLanguage}
        toggleLanguageSelector={props.toggleLanguageSelector}
      />
      <Content className="content-container">
        { props.children }
      </Content>
    </Layout>
  )
}

const mapStateToProps = ( store ) =>{
  return store
}

export default connect( mapStateToProps, { getCurrentUser })(LoginLayout)