import React, { Component } from "react";
import ThankYouImage from "../../../assets/images/thank_you.png";

export default class WorkApprovalReject extends Component {
  render() {
    return (
      <div style={
        {
          display: "flex",
          justifyContent: "space-between",
          flexFlow: "column",
          alignItems: "center",
          marginTop: "40px",
          marginBottom: "100px",
          lineHeight: "1.5rem"
        }}
      >
        <img src={ThankYouImage} style={{ marginBottom: "20px" }} width="267px" height="130px" alt="Thank You" />
        <h3 style={{ fontColor: "#565868", fontSize: "20px" }}> { "Profile Rejected" } </h3>
        <p style={{ fontColor: "#9493A1", fontSize: "14px" }}> {"Thank you for showing interest in being a creator with Dailyhunt.\
However, after going through the details (Social Media Profile/ Age/ your previous work, etc) you submitted, we regret to inform you that your profile does not meet our content requirements criteria at the moment."} </p>
      </div>
    )
  }
} 