import { SET_REGION, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, SET_MOBILE_VIEW, RESET_ERROR, SET_CONFLICT_ACCOUNTS, SET_UTM_PARAMS, SET_POST_TYPE } from '../../constants/ActionTypes'
import { types } from 'babel-core';

const INIT_STATE = {
  error: "",
  loading: false,
  message: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', message: '', loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: '', message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: '' };
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: '', message: '' };
    }
    case SET_MOBILE_VIEW: {
      return { ...state, isMobile: action.isMobileView };
    }
    case SET_REGION: {
      return { ...state, region: action.region };
    }
    case SET_CONFLICT_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts
      }
    case RESET_ERROR: {
      return { ...state, error: '', message: '', loading: false }
    }
    case SET_UTM_PARAMS: {
      return {
        ...state,
        utmParams: action.utmParams,
        currentUserEmail: action.currentUserEmail
      }
    }
    case SET_POST_TYPE:{
      return {
        ...state,
        postType: action.postType
      }
    }
    default:
      return state;
  }
}
