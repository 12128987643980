import * as types from '../../constants/ActionTypes';

export const getQuickAnalytics = ( userId ) => {
  
  return {
    type: types.GET_QUICK_ANALYTICS,
    userId
  }
}

export const getQuickAnalyticsSuccess = ({ data }) => {
  return {
    type: types.GET_QUICK_ANALYTICS_SUCCESS,
    quickAnalytics: data
  }
}


