import * as types from '../../constants/ActionTypes';

export const getLocations = () => {
  return {
    type: types.GET_LOCATIONS
  }
}

export const getLocationsSuccess = ({ data }) => {
  return {
    type: types.GET_LOCATIONS_SUCCESS,
    locationList: data
  }
}
