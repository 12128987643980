import React from "react";
import { Steps } from 'antd';
import _Get from "lodash/get";
import _Map from "lodash/map";
import _IndexOf from "lodash/indexOf";

import "./styles.css"
import _ from "lodash";

const { Step } = Steps;

let onBoardingSteps = [
  "login",
  "phone-verification",
  "profile-submission",
  "work-approval-pending",
]

const _getSteps = (props) => {

  if( props.region === "DH_MENA"){
    _.remove(onBoardingSteps, step => step === "phone-verification")
  }

  if (!(onBoardingSteps.includes("bank-details-submission") && onBoardingSteps.includes("bank-details-approval-pending"))) {
    if (props.onBoarded) {
      if (props.paymentType == "ICC" || props.paymentType == "OGC") {
        onBoardingSteps.push("bank-details-submission")
        onBoardingSteps.push("bank-details-approval-pending")
      }
    }
  }
  return (
    _Map(onBoardingSteps, (step) => {
      return (
        <Step onClick={props.toggleOnBoardingModal.bind(this, [true, step])} title={step} />
      )
    })
  )
}

const _getCurrentStep = (onboardingScreen) => {

  return _IndexOf(onBoardingSteps, onboardingScreen)
}

const OnBoardingStepsBar = (props) => {
  const { onboardingScreen, direction, region } = props
  const isVertical = direction === "vertical"
  return (
    <div style={{
      position: `${isVertical ? "" : "fixed"}`,
      width: `${isVertical ? "" : "100%"}`,
      marginBottom: `${isVertical ? "1rem" : ""}`
    }}
      className="onboarding-steps-bar-container">
      <Steps direction={direction} className="onboarding-steps-bar" size="small" current={_getCurrentStep(onboardingScreen)}>
        {_getSteps(props)}
      </Steps>
    </div>
  )
}

export default OnBoardingStepsBar;