import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import _ from "lodash";
import asyncComponent from "../../util/asyncComponent";
import {
  PERMISSION_VIEW_POST,
  PERMISSION_UPDATE_POST,
  PERMISSION_UPDATE_PROFILE,
  PERMISSION_CREATE_POST,
  PERMISSION_APPROVE_CREATOR
} from "../../constants/Permissions";
import ErrorBoundComponent from "../../components/ErrorBoundComponent"
import DashBoardLayout from "../../components/DashboardLayout/dashBoardLayout";
import Auxiliary from "../../util/Auxiliary";



class AppRoutes extends ErrorBoundComponent {
  _getRoutes = (permissions, configData) => {
    if (permissions && _.isArray(permissions) && permissions.length > 0) {
      return (
        <Switch>
          {/* <Route path={`/app/dashboard`} component={asyncComponent(() => import('../../containers/Post/postList'))} />  */}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/internal`} component={asyncComponent(() => import('../../containers/Internal/landingPage'))} /> }
          {permissions.includes(PERMISSION_VIEW_POST) && <Route path={`/app/post/list`} component={asyncComponent(() => import('../../containers/Post/postList'))} />}
          {permissions.includes(PERMISSION_VIEW_POST) && configData.enableWallOfFame && <Route path={`/app/post/featured/wall`} component={asyncComponent(() => import('../../containers/Post/wallOfFame'))} />}
          {permissions.includes(PERMISSION_CREATE_POST) && <Route path={`/app/post/create`} component={asyncComponent(() => import('../../containers/Post/upsert/postUpsert'))} />}
          {permissions.includes(PERMISSION_UPDATE_POST) && <Route path={`/app/post/edit`} component={asyncComponent(() => import('../../containers/Post/upsert/postUpsert'))} />}
          {permissions.includes(PERMISSION_UPDATE_PROFILE) && <Route path={`/app/profile`} component={asyncComponent(() => import('../../containers/Profile/profile'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/agency`} component={asyncComponent(() => import('../../containers/Agency/agencyList'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/creator`} component={asyncComponent(() => import('../../containers/Creator/creatorList'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/account`} component={asyncComponent(() => import('../../containers/Account/accountList'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/source`} component={asyncComponent(() => import('../../containers/Source/sourceList'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/commConfig`} component={asyncComponent(() => import('../../containers/commCardConfig/upsertCommCardConfig'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/approve-details`} component={asyncComponent(() => import('../../containers/WorkApproval/workApprovalList'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/bank-details`} component={asyncComponent(() => import('../../containers/BankApproval/bankApproval'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/ogcToUgc`} component={asyncComponent(() => import('../../containers/ogcToUgc/ogcToUgc'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/invoice`} component={asyncComponent(() => import('../../containers/Invoice/downloadInvoice'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/campaigns`} component={asyncComponent(() => import('../../containers/Campaigns/campaigns'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) && <Route path={`/app/markFeaturedPost`} component={asyncComponent(() => import('../../containers/FeaturedPost/markFeaturedPost'))} />}
          {permissions.includes(PERMISSION_APPROVE_CREATOR) ? <Route render={() => <Redirect to={`/app/internal`} />} /> : <Route render={() => <Redirect to={`/app/post/list`} />} />}
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route render={() => <Auxiliary error={{ message: "You don't have required permissions!! Please contact support." }} />} />
        </Switch>
      )
    }
  }

  _render = () => {
    const { currentUser, configData } = this.props
    let permissions = currentUser.permissions && currentUser.permissions.length > 0 ? currentUser.permissions : [];
    return (
      <DashBoardLayout {...this.props} currentUser={currentUser}>
        {
          this._getRoutes(permissions, configData)
        }
      </DashBoardLayout>
    )
  }
}

export default AppRoutes;