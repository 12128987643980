import React from "react";
import { Link } from "react-router-dom";
import { Layout, Icon } from "antd"
import _ from "lodash";
import { connect } from "react-redux";
import supportedLanguages from "../../locales/supportedLanguages";
import { getCurrentUser } from "../../appRedux/actions/currenUser.action";
import { getProfileDetails } from "../../appRedux/actions/profile.action";
import { logoutUser } from "../../clientServices/auth";
import ErrorBoundComponent from "../ErrorBoundComponent";
import "./header.styles.css";

const { Header } = Layout;

class LoginModuleHeader extends ErrorBoundComponent {

  constructor(props) {
    super(props)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let stateObj = _.cloneDeep(prevState)
    const currentAppLanguage = _.get(_.filter(supportedLanguages, ({ value }) => value === nextProps.appLanguage), "0.label")
    stateObj.currentAppLanguage = currentAppLanguage;
    return stateObj;
  }

  _logout = async () => {
    await logoutUser();
    this.props.getCurrentUser();
    this.props.history.push("/login")
  }

  _languageSelector = () => {
    return (
      <div onClick={this.props.toggleLanguageSelector.bind(this, true)} className="language-selector">
        {this.state.currentAppLanguage}
      </div>
    )
  }

  _logo = () => {
    return (
      <Link to="/">

        <img alt="" className="logoImage-login" src={require("assets/images/logo-dark.svg")} />
      </Link>
    )
  }

  _render = () => {
    return (
      <Header className="d-flex align-items-center loginNavBar">
        <div className="d-flex justify-content-start">
          {this._logo()}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {this._languageSelector()}
          {
            this.props.showLogout &&
            (this.props.isMobile ?
              <Icon type="poweroff" onClick={this.props.logout} />
              :
              <div style={{ cursor:"pointer" }} className="d-flex align-items-center" onClick={this.props.logout} >
               Logout
              </div>
            )
          }
        </div>
      </Header>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isMobile: _.get(store, "common.isMobile")
  };
}

export default connect(mapStateToProps, { getProfileDetails, getCurrentUser })(LoginModuleHeader);