import React from "react";
import { Link } from "react-router-dom";
import { Layout, Icon, Drawer, Menu, Row, Col, Switch } from "antd"
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { connect } from "react-redux"
import supportedLanguages from "../../locales/supportedLanguages";
import { logoutUser } from "../../clientServices/auth"
import { getProfileDetails } from "../../appRedux/actions/profile.action";
import UserInfo from "../UserInfo";
import { PERMISSION_APPROVE_CREATOR } from "../../constants/Permissions";
import ErrorBoundComponent from "../ErrorBoundComponent";
import { appendImageServingUrl, replaceMacroWithImageServingUrl } from "../../util/imageUtils";
import OnBoardingStepsBar from "../../containers/OnboardingFlows/onBoardingStepsBar";
import OnBoardingFlowContainer from "../../containers/OnboardingFlows/onBoardingModalContainer";


import "./header.styles.css";
import { BCDN_IMAGE_MACRO } from "../../constants/uiConstants";

const { Header } = Layout;
const { SubMenu } = Menu;

const noModalRequiredOnBoarding = ["bank-details-submission", "bank-details-approval-pending"]

class AppModuleHeader extends ErrorBoundComponent {
  constructor(props) {
    super(props)
    this.state = {
      showOnboardingModal: false,
      drawerVisible: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let stateObj = _.cloneDeep(prevState)
    const currentAppLanguage = _.get(_.filter(supportedLanguages, ({ value }) => value === nextProps.appLanguage), "0.label")
    stateObj.currentAppLanguage = currentAppLanguage;
    return stateObj;
  }

  componentDidMount() {
    const { currentUser } = this.props
    if (currentUser) {
      // if(currentUser.nextScreen === "dashboard"){
      //   const userId = currentUser.userId
      //   userId && this.props.getProfileDetails( userId )
      // }

    }
  }

  _postTypeSwitch = () => {
    return (
      <Row>
        <div>
          <Switch checkedChildren={"Crawled"} unCheckedChildren={"Created"} onChange={this.props.togglePostType} />
        </div>
      </Row>
    )
  }

  _closeDrawer = () => {
    this.setState({
      drawerVisible: false
    })
  }
  _myProfile = () => {
    this.props.history.push("/app/profile");
  }

  _mobileDrawer = () => {
    return (
      <Drawer
        placement={"left"}
        closable={false}
        title={<>{this._languageSelector()}{this.props.currentUser.crawlEnabled && this._postTypeSwitch()}</>}
        onClose={() => this.setState({ drawerVisible: false })}
        visible={this.state.drawerVisible}
      >
        <div style={{ padding: "10%" }}>
          {
            this.props.onboardingScreen &&
            <OnBoardingStepsBar region={this.props.region} direction={"vertical"} toggleOnBoardingModal={this._toggleOnBoardingModal} onboardingScreen={this.props.onboardingScreen} />
          }
          {
            this.props.onboardingScreen &&
            this._onBoardingFlow()
          }
          <Menu
            defaultSelectedKeys={['/post/list']}
            defaultOpenKeys={['agency']}
            mode="inline"
            onClick={this._closeDrawer}
          >
            <Menu.Item key="/post/list" ><Link className="headerMenu" to="/">Dashboard</Link></Menu.Item>
            <Menu.Item key="/post/list?archived=true" ><Link className="headerMenu" to="/app/post/list?archived=true">Archived</Link></Menu.Item>
            {this.props.config && this.props.config.enableWallOfFame && <Menu.Item key="/featured/wall" ><div onClick={this._handleCreateRouting} className="headerMenu">{this.props.t("WALL_OF_FAME")} </div></Menu.Item>}
            {
              _.get(this.props, "currentUser.permissions.length") > 0 &&
              _.get(this.props, "currentUser.permissions").includes(PERMISSION_APPROVE_CREATOR) &&
              <SubMenu
                key="agency"
                title={
                  <span>
                    <Icon type="user" />
                    <span>Manage Accounts</span>
                  </span>
                }
              >
                <Menu.Item key="/app/source" ><Link to="/app/source" className="headerMenu">Source</Link></Menu.Item>
                <Menu.Item key="/app/commConfig"><Link to="/app/commConfig" className="headerMenu">Communication Card Config</Link></Menu.Item>
                <Menu.Item key="/app/account"><Link to="/app/account" className="headerMenu">Account</Link></Menu.Item>
                <Menu.Item key="/app/creator"><Link to="/app/creator" className="headerMenu">Creator</Link></Menu.Item>
                <Menu.Item key="/app/agency"><Link to="/app/agency" className="headerMenu">Agency</Link></Menu.Item>
                <Menu.Item key="/app/invoice"><Link to="/app/invoice" className="headerMenu">Download Invoices</Link></Menu.Item>
              </SubMenu>
            }
            {
              _.get(this.props, "currentUser.permissions.length") > 0 &&
              _.get(this.props, "currentUser.permissions").includes(PERMISSION_APPROVE_CREATOR) &&
              <SubMenu
                key="approveDetails"
                title={
                  <span>
                    <Icon type="user" />
                    <span>Approve Details</span>
                  </span>
                }
              >
                <Menu.Item key="/app/approve-details"><Link to="/app/approve-details" className="headerMenu">Work Details</Link></Menu.Item>
              </SubMenu>
            }
          </Menu>
        </div>
      </Drawer>
    );
  }

  _icon = () => {
    return (
      <Icon
        type="menu"
        onClick={() => this.setState({ drawerVisible: true })}
        style={{ color: "white" }}
      />
    )
  }

  _languageSelector = () => {
    return (
      <div onClick={this.props.toggleLanguageSelector.bind(this, true)} className="language-selector">
        {this.state.currentAppLanguage}
      </div>
    )
  }

  _userInfo = () => {
    let profileImageUrl;
    if(_.get(this.props, "profileDetails.profileDetails.profileImage.url") && _.get(this.props, "profileDetails.profileDetails.profileImage.url").startsWith(BCDN_IMAGE_MACRO)){
      profileImageUrl = replaceMacroWithImageServingUrl(_.get(this.props, "profileDetails.profileDetails.profileImage.url"),this.props.config['imageServiceUrl'])
    }else if(_.get(this.props, "profileDetails.profileDetails.profileImage.url")){
      profileImageUrl =  appendImageServingUrl( _.get(this.props, "profileDetails.profileDetails.profileImage.url"), this.props.config["imageServiceUrl"])
    }
    return (
      <UserInfo
        currentUser={this.props.currentUser}
        profileImageUrl={ profileImageUrl }
        myProfile={this._myProfile}
        onLogout={this.props.logout} />
    )
  }

  _logout = async () => {
    await logoutUser();
    this.props.getCurrentUser();
  }

  _logo = () => {
    return (
      <>
        <Link to="/">
          <img alt="" className="logoImage-dashboard" src={require("assets/images/logo-light.svg")} />
        </Link>
      </>
    )
  }

  _getMobileHeader = () => {
    return (
      <Header className="appNavBar">
        {this._mobileDrawer()}
        <div className="d-flex justify-content-start">
          {this._icon()}
        </div>
        <div className="d-flex justify-content-between">
          {this._logo()}
        </div>
        <div className="d-flex justify-content-end">
          {this._userInfo()}
        </div>
      </Header>
    );
  }

  _onBoardingFlow = () => {
    return (
      <OnBoardingFlowContainer
        {...this.props}
        showOnboardingModal={this.state.showOnboardingModal}
        nextScreen={_.get(this.props, "currentUser.nextScreen")}
        toggleOnBoardingModal={this._toggleOnBoardingModal}
        closeDrawer={this._closeDrawer}
        step={this.state.step}
      />
    )
  }

  _toggleOnBoardingModal = async (visibility) => {
    await this.setState({
      step: visibility[1]
    })
    if (noModalRequiredOnBoarding.includes(this.state.step)) {
      switch (this.state.step) {
        case ("bank-details-submission"):
          return this.props.history.push("/app/profile?bankDetailsSubmission=true")
      }
    } else {
      this.setState({
        showOnboardingModal: visibility[0],
      })
    }
  }


  _handleCreateRouting = (postType) => {
    return this.props.history.push(`/app/post/featured/wall`)
  }

  _getDesktopHeader = () => {
    return (
      <>
        {
          this.props.onboardingScreen &&
          <OnBoardingStepsBar
            direction={"horizontal"}
            region ={ this.props.region}
            toggleOnBoardingModal={this._toggleOnBoardingModal}
            onboardingScreen={this.props.onboardingScreen}
            onBoarded={_.get(this.props, "currentUser.creatorState.onboarding-complete")}
            paymentType={_.get(this.props, "profileDetails.metaInfo.paymentType")}
          />
        }
        {
          this.props.onboardingScreen &&
          this._onBoardingFlow()
        }
        <Header style={{ marginTop: `${this.props.onboardingScreen ? "2rem" : ""}` }} className="appNavBar">
          <div className="d-flex justify-content-start align-items-center">
            {this._logo()}
            <Menu
              className="headerMainMenu"
              theme="dark"
              mode="horizontal"
              onClick={({ key }) => {
                  this.setState({
                    selectedKey: key
                  })
              }}
              selectedKeys={[this.state.selectedKey]}
              defaultSelectedKeys={['/post/list']}
            >
              <Menu.Item key="/post/list" ><Link className="headerMenu" to="/">{this.props.t("TOPBAR_DASHBOARD")} </Link></Menu.Item>
              <Menu.Item key="/post/list?archived=true" ><Link className="headerMenu" to="/app/post/list?archived=true">{this.props.t("ARCHIVED_DASHBOARD")}</Link></Menu.Item>
              {this.props.config && this.props.config.enableWallOfFame && <Menu.Item key="/featured/wall" ><div onClick={this._handleCreateRouting} className="headerMenu">{this.props.t("WALL_OF_FAME")} </div></Menu.Item>}
              {
                _.get(this.props, "currentUser.permissions.length") > 0 &&
                _.get(this.props, "currentUser.permissions").includes(PERMISSION_APPROVE_CREATOR) &&
                <SubMenu key="manageAccounts" title="Manage Accounts">
                  <Menu.Item key="/app/commConfig"><Link to="/app/commConfig" className="headerMenu">Communication Card Config</Link></Menu.Item>
                  <Menu.Item key="/app/source"><Link to="/app/source" className="headerMenu">Source</Link></Menu.Item>
                  <Menu.Item key="/app/account"><Link to="/app/account" className="headerMenu">Account</Link></Menu.Item>
                  <Menu.Item key="/app/creator"><Link to="/app/creator" className="headerMenu">Creator</Link></Menu.Item>
                  <Menu.Item key="/app/agency"><Link to="/app/agency" className="headerMenu">Agency</Link></Menu.Item>
                  <Menu.Item key="/app/ogcToUgc"><Link to="/app/ogcToUgc" className="headerMenu">OGC To ICC</Link></Menu.Item>
                  <Menu.Item key="/app/invoice"><Link to="/app/invoice" className="headerMenu">Download Invoices</Link></Menu.Item>
                  <Menu.Item key="/app/campaigns"><Link to="/app/campaigns" className="headerMenu">Create Campaigns</Link></Menu.Item>
                  <Menu.Item key="/app/markFeaturedPost"><Link to="/app/markFeaturedPost" className="headerMenu">Mark Featured Post</Link></Menu.Item>
                </SubMenu>
              }
              {
                _.get(this.props, "currentUser.permissions.length") > 0 &&
                _.get(this.props, "currentUser.permissions").includes(PERMISSION_APPROVE_CREATOR) &&
                <SubMenu title="Approve Details">
                  <Menu.Item key="/app/approve-details"><Link to="/app/approve-details" className="headerMenu">Work Details</Link></Menu.Item>
                  <Menu.Item key="/app/bank-details"><Link to="/app/bank-details" className="headerMenu">Bank Details</Link></Menu.Item>
                </SubMenu>
              }
            </Menu>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {this.props.currentUser.crawlEnabled && this._postTypeSwitch()}
            {this._languageSelector()}
            {this._userInfo()}
          </div>
        </Header>
      </>
    )
  }

  _render = () => {
    if (_.get(this.props, "isMobile")) {
      return this._getMobileHeader();
    } else {
      return this._getDesktopHeader();
    }
  }
}

const mapStateToProps = (store) => {
  return {
    config: _.get(store, "config.config"),
    currentUser: _.get(store, "currentUser.currentUser.data"),
    profileDetails: _.get(store, "profile.profileDetails.data.row.0"),
    isMobile: _.get(store, "common.isMobile"),
    region : _.get(store, "common.region")
  };
}

export default connect(mapStateToProps, { getProfileDetails })(withTranslation()(AppModuleHeader));