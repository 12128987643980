import * as types from '../../constants/ActionTypes';

const initialState = {
  imageUrl: [],
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.UPLOAD_IMAGE:
      return {
        ...state,
        imageUrl: state.imageUrl
      }
    case types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageUrl: [ ...state.imageUrl, action.imageUrl],
      }
    default:
      return state
  }
}
