import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Axios from "axios";

import {notification as Notification,message} from "antd";
import _Get from "lodash/get";
import {
    getInvoiceListSuccess,
    getZipInvoicesSuccess
} from "../actions/invoices.action";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";

const getInvoiceList = async (params) => {
  const creatorData = {
      "creator": params.creatorIDEmail || "",
      "month": params.month || "",
      "invoiceState": params.invoiceStatusSelected || ""
  }
  try{
    let data = await Axios.post(`/api/invoice/getInvoiceList`, creatorData);
    return data;
  }catch(error){
    throw error;
  }
}

function* getInvoiceListSaga({params}) {
    try {
      const data = yield call(getInvoiceList, params)
      yield put(getInvoiceListSuccess(data))
    } catch (error) {
      yield put(fetchError(error));
    }
}


const getZipInvoice = async (params) => {
    const creatorData = {
        "creator": params.creatorIDEmail || "",
        "month": params.month || ""
    }
    try{
      let data = await Axios.post(`/api/invoice/getZipInvoice`, creatorData);
      return data;
    }catch(error){
      throw error;
    }
  }
  
  function* getZipInvoiceSaga({params}) {
      try {
        const data = yield call(getZipInvoice, params)
        yield put(getZipInvoicesSuccess(data))
      } catch (error) {
        yield put(fetchError(error));
      }
  }
export function* invoicesSagaList() {
    yield takeEvery(types.GET_INVOICE_LIST, getInvoiceListSaga);
    yield takeEvery(types.GET_ZIP_INVOICES, getZipInvoiceSaga);
}
  
  
export default function* rootSaga() {
    yield all([fork(invoicesSagaList)])
}
