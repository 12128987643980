import * as types from '../../constants/ActionTypes';

const initialState = {
  quickAnalytics:null, 
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    
    case types.GET_QUICK_ANALYTICS:
      return {
        ...state,
        quickAnalytics: null
      }
    case types.GET_QUICK_ANALYTICS_SUCCESS:
  
      return {
        ...state,
        quickAnalytics: action.quickAnalytics,
      }
    default:
      return state
  }
}


