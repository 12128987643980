// Post states
export const PUBLISHED = { name: "Published", color: "green" }
export const PUBLISH_FAILED = { name: "Publish failed", color: "red" }
export const UNPUBLISHED = { name: "Unpublished", color: "red" }
export const REJECT = { name: "Reject", color: "red" }
export const SUBMITTED_FOR_DHPUBLISH_REVIEW = { name: "In Review", color: "orange" }
export const REJECTED = { name: "Rejected", color: "red" }
export const PERMANENTLY_REJECTED = { name: "Rejected", color: "red" }
export const DRAFT = { name: "Draft", color: "orange" }
export const CREATED = { name: "Created", color: "orange" }


export const VIEW_POST = "VIEW_POST"
export const UPDATE_POST = "UPDATE_POST"
export const DELETE_POST = "DELETE_POST"
export const APPROVE_CREATOR = "APPROVE_CREATOR"
export const UPDATE_ADVANCE_META = "UPDATE_ADVANCE_META"
export const PUBLISH_POST = "PUBLISH_POST"
export const REJECT_POST = "REJECT_POST"
export const CREATE_POST = "CREATE_POST"

//Post types
export const PHOTOGALLERY = "FORMAT_GALLERY"
export const TEXT = "FORMAT_ARTICLE"
export const IMAGE = "FORMAT_IMAGE"
export const VIDEO = "FORMAT_VIDEO"
export const GIFVIDEO = "GIFVIDEO" // TODO Change the name according to the json keys

//Image Macro
export const BCDN_IMAGE_MACRO = "#DH_BCDN_BASE_URL#/"
export const REP_IMAGE_MACRO = "##REPCDN-NEW##/"

//Post Actions 
export const create = "CREATED"
export const update = "SUBMITTED_FOR_DHPUBLISH_REVIEW"
export const unpublish = "UNPUBLISHED"
export const reject = "REJECTED"

//language Codes

//Post Filter values

export const PostStateValues = {
  "DRAFT": "CREATED,DRAFT,SUBMITTED,SUBMITTED_FOR_DHPUBLISH_REVIEW,PENDING",
  "PUBLISHED": "PUBLISHED,SENSITIVE,UNDER_REVIEW",
  "REJECTED": "SUSPENDED,UNPUBLISHED,PERMANENTLY_REJECTED,REJECTED"
}

export const RejectReasons = [
  {
    name: "Adult Content",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Genre Glamour Sub-Genre Nsfw",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Child Abuse",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Violence Acts - Any Cruelty (Human, Animals, Etc.), Fatal Acts",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Promoting Human/Organ Trafficking",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Sneaky Content - Captured Without Knowledge",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "No Promotion Of The Use Of Weapons, Bombs, Drugs, Or Any Other Regulated Goods Prohibited By-Laws",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Promotion Of Terrorism And Other Extreme Ideological Thoughts",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Hate Speech",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Any Brand Promotion",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Genre & Sub-Genre Mismatch",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "Different language Article",
    text: "REJECT",
    videos: "SUBJECTIVE",
    image: "SUBJECTIVE"
  }, {
    name: "Fake news",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  }, {
    name: "No Content ( Only for Text articles )",
    text: "REJECT",
    videos: "NOT_APPLICABLE",
    image: "NOT_APPLICABLE"
  }, {
    name: "Hyper Links Missing",
    text: "REJECT",
    videos: "NOT_APPLICABLE",
    image: "NOT_APPLICABLE"
  },
  {
    name: "Duplicate Posts",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Incomplete Content ( Missing Links/images/paragraphs)",
    text: "REJECT",
    videos: "NOT_APPLICABLE",
    image: "NOT_APPLICABLE"
  }, {
    name: "For title and content getting mismatched",
    text: "REJECT",
    videos: "SUBJECTIVE",
    image: "SUBJECTIVE"
  }, {
    name: "For video in content getting mismatched",
    text: "NOT_APPLICABLE",
    videos: "REJECT",
    image: "NOT_APPLICABLE"
  }, {
    name: "Without any visual content(For videos like white screen)",
    text: "NOT_APPLICABLE",
    videos: "REJECT",
    image: "NOT_APPLICABLE"
  }, {
    name: "Incorrect HashTags",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Account Blocked",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Spelling mistake on title",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Video is not supported",
    text: "REJECT",
    videos: "REJECT",
    image: "NOT_APPLICABLE"
  },
  {
    name: "Bad quality image/card/gallery/meme",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Spelling mistake in the content",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Watermark/Attribution",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Irrelevant location tagging",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },
  {
    name: "Bad quality content (Non-factual content)",
    text: "REJECT",
    videos: "REJECT",
    image: "REJECT"
  },


]