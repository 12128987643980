import * as types from '../../constants/ActionTypes';

const initialState = {
  sourceList: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_SOURCE_LIST_BY_FILTER:
      return {
        ...state,
        loading: true,
        sourceList: state.sourceList
      }
    case types.GET_SOURCE_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        sourceList: action.sourceList,
        loading: false,
        nextPageUrl: action.sourceList.nextPageUrl
      }
    case types.UPSERT_SOURCE:
      return {
        ...state,
        loading: true,
        sourceUpsert: state.sourceUpsert
      }
    case types.UPSERT_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceUpsert: action.sourceUpsert
      }
    case types.GET_SEARCH_SOURCE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        sourceSearch: action.sourceSearch
      }
    case types.RESET_SOURCE_STATE:
      return {
        ...state,
        sourceList: []
      }
    default:
      return state
  }
}
