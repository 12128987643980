import React from "react";
import { connect } from 'react-redux';
import { Modal, Card, Row, Col, Button, Icon, message as Message } from "antd";
import _ from "lodash";
import FacebookAuth from "react-facebook-auth";
import GoogleAuth from "react-google-login";
import ErrorBoundComponent from "../ErrorBoundComponent";
import {
  getCurrentUser
} from "../../appRedux/actions/currenUser.action";
import {
  setConflictAccounts
} from "../../appRedux/actions/common.action"
import { link, logoutUser } from "../../clientServices/auth";
import { withTranslation } from "react-i18next";

const FACEBOOK_LOGIN_TYPE = "FACEBOOK", GOOGLE_LOGIN_TYPE = "GOOGLE";

class AccountLinking extends ErrorBoundComponent {

  static getDerivedStateFromProps(newProps) {
    let stateObj = {}
    if (newProps.visible) {
      stateObj["visible"] = true
    }
    return stateObj
  }

  _authenticate = async (response) => {
    let currentUser = Message.loading("Linking in progress...");
    const { userID, accessToken: fbAccessToken, expiresIn: fbTokenTTL, name, email } = response;
    const user = {
      loginType: FACEBOOK_LOGIN_TYPE,
      fbUserId: userID,
      agencyLogin: false,
      fbAccessToken,
      fbTokenTTL,
      name,
      email
    }
    try {
      if (user.fbUserId) {
        const userHeader = {
          userId: this.props.userId
        }
        const resp = await link(user, userHeader)
        if (resp && resp.data && resp.data.data && resp.data.data.nextScreen) {
          if(resp.data.data.nextScreen === "login"){
            await logoutUser()
          }
          if(resp.data.data.selectAccount){
            this.props.setConflictAccounts(resp.data.data.selectAccount)
          }
          Message.success("Linking successful")
        } else {
          Message.error("Linking failed")
        }
      } else {
        currentUser = null
      }
    } catch (error) {
      Message.error(error.message || "Linking failed")
      console.error(error)
    } finally{
      this._onCancel()
      this.props.getCurrentUser()
    }
  };

  _responseGoogle = async (response) => {
    let  currentUser, progressMsg;
    const { googleId, tokenObj, profileObj } = response;
    const { id_token, expires_in } = tokenObj
    const user = {
      loginType: GOOGLE_LOGIN_TYPE,
      googleId,
      agencyLogin: false,
      googleAccessToken: id_token,
      googleTokenTTL: expires_in,
      name: profileObj.name,
      email: profileObj.email
    }

    try {
      if (user.googleId) {
        progressMsg = Message.loading("Linking in progress...");
        const userHeader = {
          userId: this.props.userId
        }
        const resp = await link(user, userHeader)
        if (resp && resp.data && resp.data.data && resp.data.data.nextScreen) {
          if(resp.data.data.nextScreen === "login"){
            await logoutUser()
          }
          if(resp.data.data.selectAccount){
            this.props.setConflictAccounts(resp.data.data.selectAccount)
          }
          Message.success("Linking successful")
        } else {
          Message.error("Linking failed")
        }
      } else {
        currentUser = null
      }
    } catch (error) {
      console.error(error)
      Message.error(error.message || "Linking failed")
    } finally {
      this._onCancel()
      this.props.getCurrentUser()
    }
  };


  _MyFacebookButton = ({ onClick }) => (
    <Button shape="round" style={{textTransform:"inherit", backgroundColor:"#3c5a99", color:"white"}}  onClick={onClick}>
      <Icon type="facebook" theme="filled" style={{ fontSize: 22 }} /> 
      <span>{"Connect with Facebook"}</span>
    </Button>
  );


  _content = () => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
        <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
          <h1>{"Link other accounts to your Dailyhunt Account"}</h1>
        </div>
        <Row style={{width:"100%"}}>
          <Col style={{display:"flex", justifyContent:"center"}} span={24}>
            <GoogleAuth
              clientId={this.props.config["google"]["client_id"]}
              tag="div"
              render={(renderProps) => {
                return (
                  <Button
                    shape="round"
                    style={{textTransform:"inherit", backgroundColor:"#dd4b39", color:"white"}}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <Icon type="google" style={{ fontSize: 22 }} />
                    <span>{"Connect with Google"}</span>
                  </Button>
                )
              }}
              onSuccess={this._responseGoogle}
              onFailure={this._responseGoogle}
              cookiePolicy={"single_host_origin"}
            />

          </Col>
          <Col  style={{display:"flex", justifyContent:"center"}} span={24}>
            <FacebookAuth
              appId={this.props.config["facebook"]["app_id"]}
              callback={this._authenticate}
              component={this._MyFacebookButton}
              isMobile={false}
            />
          </Col>
        </Row>
      </div>
    )
  }

  _onCancel = () => {
    this.props.resetNewUser()
    this.setState({
      visible: false
    })
  }

  _render = () => {
    return (
      <Modal
        visible={this.state.visible}
        width={`${this.props.isMobile ? "20rem" : "30rem"}`}
        centered={true}
        onCancel={this._onCancel}
        closable={true}
        maskClosable={false}
        footer={null}
        bodyStyle={{ padding: 0 }}
        title={null}
      >
        <Card >
          {
            this._content()
          }
        </Card>
      </Modal>
    )
  }
}


function mapStateToProps(store) {
  return {
    config: _.get(store, "config.config"),
  }
}

export default connect( mapStateToProps, {getCurrentUser, setConflictAccounts})(withTranslation()(AccountLinking)); // resetNewUser={()=>{
  //   this.setState({
  //     newUser: false
  //   })
  // }}