import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";


import {
  getLanguagesSuccess
} from "../actions/languages.action";

const getLanguagesAxios = async () => {

  try{
    let data = await Axios.get(`/api/languages`, {
        userId : 12
    });
    const valueLabelObject = data.data.map(item => {
      return {
        label : item.name,
        value : item.isoCode
      }
    })
    return {data : valueLabelObject};
  }catch(error){
    throw error;
  }
}


function* fetchLanguageListRequest(params) {
  try {
    const data = yield call(getLanguagesAxios);
    yield put(getLanguagesSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* fetchLanguageList() {
  yield takeEvery(types.GET_LANGUAGES, fetchLanguageListRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchLanguageList)])
}