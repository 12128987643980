import * as types from '../../constants/ActionTypes';

const initialState = {
  invoiceList:[], 
  loading: false,
  zipInvoice: "",
  showMsg: false
}

export default (state = initialState, action) => {
  switch(action.type){
    
    case types.GET_INVOICE_LIST:
      return {
        ...state,
        loading: true,
        invoiceList: state.invoiceList
      }
    case types.GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceList: action.invoiceList,
        showMsg: action.invoiceList.length === 0 ? true : false
      } 
    case types.GET_ZIP_INVOICES:
      return {
        ...state,
        loading: true,
        zipInvoice: state.zipInvoice
      }
    case types.GET_ZIP_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        zipInvoice: action.zipInvoice,
      } 
    case types.RESET_INVOICE_STATE:
      return {
        ...state,
        invoiceList: [],
        zipInvoice: ""
      }
    default:
      return state
  }
}
