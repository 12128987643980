import * as types from '../../constants/ActionTypes';

export const getConfigData = () => {
  return {
    type: types.GET_CONFIG_DATA
  }
}

export const getEditorApiKey = () => {
  return {
    type: types.GET_EDITOR_API_KEY
  }
}

export const getEditorApiKeySuccess = ({data}) => {
  return {
    type: types.GET_EDITOR_API_KEY_SUCCESS,
    editorApikey: data
  }
}

export const getConfigDataSuccess = ({data}) => {
  return {
    type: types.GET_CONFIG_DATA_SUCCESS,
    config: data
  }
}

export const getCommCardConfigData = (dhUserId, paymentType) => {
  return {
    type: types.GET_COMM_CONFIG_DATA,
    dhUserId,
    paymentType
  }
}

export const getCommCardConfigDataSuccess = ({data}) => {
  return {
    type: types.GET_COMM_CONFIG_DATA_SUCCESS,
    commConfig: data
  }
}