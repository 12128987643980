import * as types from '../../constants/ActionTypes';

const initialState = {
  config:null,
  commConfig: null, 
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    
    case types.GET_CONFIG_DATA:
      return {
        ...state,
        config: null
      }
    case types.GET_CONFIG_DATA_SUCCESS:
  
      return {
        ...state,
        config: action.config,
      }
    case types.GET_EDITOR_API_KEY:
      return {
        ...state,
        editorApiKey: null
      }
    case types.GET_EDITOR_API_KEY_SUCCESS:
      return {
        ...state,
        editorApiKey: action.editorApikey,
      }
  
    case types.GET_COMM_CONFIG_DATA:
      return {
        ...state,
        commConfig: null
      } 
    case types.GET_COMM_CONFIG_DATA_SUCCESS: 
      return {
        ...state,
        commConfig: action.commConfig
      }   
    default:
      return state
  }
}


