import * as types from '../../constants/ActionTypes';

export const getPostListByFilter = (userId, filters, pageNumber, pageSize) => {
  return {
    type: types.GET_POST_LIST_BY_FILTER,
    userId,
    filters,
    pageNumber,
    pageSize
  }
}

export const getPostListByFilterSuccess = ({ data }) => {
  return {
    type: types.GET_POST_LIST_BY_FILTER_SUCCESS,
    postList: data
  }
}

export const getFeaturedPost = (userId) => {
  return {
    type: types.GET_FEATURED_POST,
    userId,
  }
}

export const getFeaturedPostSuccess = ({data}) => {
  return {
    type: types.GET_FEATURED_POST_SUCCESS,
    featuredPostList: data
  }
}

export const upsertFeaturedPost = ({postIds,userId}) => {
  return {
    type: types.UPSERT_FEATURED_POSTS,
    postIds,
    userId
  }
}

export const upsertFeaturedPostSuccess = ({data}) => {
  return {
    type: types.UPSERT_FEATURED_POSTS_SUCCESS,
    featuredPosts: data
  }
}

export const getCampaigns = (userId) => {
  return {
    type: types.GET_CAMPAIGNS,
    userId,
  }
}

export const getCampaignsSuccess = ({data}) => {
  return {
    type: types.GET_CAMPAIGNS_SUCCESS,
    campaignsList: data
  }
}

export const createCampaign = (params) => {
  return {
    type: types.CREATE_CAMPAIGN,
    params,
  }
}

export const createCampaignSuccess = ({data}) => {
  return {
    type: types.CREATE_CAMPAIGN_SUCCESS,
    campaignCreated: data
  }
}

export const getOGCPostListByFilter = (userId, filters, count, pageValue, offsetId) => {
  return {
    type: types.GET_OGC_POST_LIST_BY_FILTER,
    userId,
    filters,
    count,
    pageValue,
    offsetId
  }
}

export const getOGCPostListByFilterSuccess = ({ data }) => {
  return {
    type: types.GET_OGC_POST_LIST_BY_FILTER_SUCCESS,
    postList: data
  }
}

export const getPostById = (userId, { postId, archived }) => {
  return {
    type: types.GET_POST_BY_ID,
    postId,
    userId,
    archived
  }
}

export const getPostByIDSuccess = ({ postById }) => {
  return {
    type: types.GET_POST_BY_ID_SUCCESS,
    postById
  }
}

export const getOGCPostById = (userId, { postId }) => {
  return {
    type: types.GET_OGC_POST_BY_ID,
    postId,
    userId
  }
}

export const getOGCPostByIDSuccess = ({ postById }) => {
  return {
    type: types.GET_OGC_POST_BY_ID_SUCCESS,
    postById
  }
}

export const upsertPost = (userId, { post }) => {
  return {
    type: types.UPSERT_POST,
    post,
    userId
  }
}

export const upsertPostSuccess = ({ post }) => {
  return {
    type: types.UPSERT_POST_SUCCESS,
    post
  }
}

export const resetState = () => {
  return {
    type: types.RESET_POST_STATE
  }
}

export const postDependencies = () => {
  return {
    type: types.GET_POST_DEPENDENCIES,
  }
}