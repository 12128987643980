 const supportedLanguages = [
  { value: "en", label: "English" },
  { value: "hi", label: "हिंदी" },
  { value: "ta", label: "தமிழ்" },
  { value: "te", label: "తెలుగు" },
  { value: "ml", label: "മലയാളം" },
  { value: "bn", label: "বাংলা" },
  { value: "mr", label: "मराठी" },
  { value: "kn", label: "ಕನ್ನಡ" },
  { value: "gu", label: "ગુજરાતી" },
  { value: "pa", label: "ਪੰਜਾਬੀ" },
  { value: "or", label: "Oriya" },
  { value: "ur", label: "اردو" }
]

export default supportedLanguages