// permissions start
// APPROVE
export const PERMISSION_APPROVE_CREATOR = "APPROVE_CREATOR";

// POST
export const PERMISSION_CREATE_POST = "CREATE_POST";
export const PERMISSION_VIEW_POST = "VIEW_POST";
export const PERMISSION_UPDATE_POST = "UPDATE_POST";
export const PERMISSION_UPDATE_COMMUNITY_META = "UPDATE_COMMUNITY_META";

// PROFILE
export const PERMISSION_UPDATE_PROFILE = "UPDATE_PROFILE";

// permssions end