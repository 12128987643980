import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import {
  message as Message
} from "antd"
import _ from "lodash"
import {
  getBankDetailsListSuccess,
  approveBankDetailsSuccess
} from "../actions/bankApproval.action";

import {
  notification as Notification
} from "antd"
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";



async function getBankList() {
  try {
    let data = await Axios.get(`/api/bankDetails`);
    return data.data;
  } catch (error) {
    throw error;
  }
}

function* BankListSaga() {
  try {
    const data = yield call(getBankList)

    yield put(getBankDetailsListSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}


async function approvebankDetails(params) {
  try {
    let data = await Axios.post(`/api/bankDetails`, {
      payload: params.payload,
      currentUser: params.userId
    });
    return data.data;
  } catch (error) {
    throw error;
  }
}

function* approvebankDetailsSaga(params) {
  try {
    const data = yield call(approvebankDetails, params)
    if (data.success) {
      Message.success(`Successfully ${params.payload.action == "bank-details-reject" ? "Rejected" : "Approved"} bank details.`)
      const data = yield call(getBankList)
      yield put(getBankDetailsListSuccess(data))
    }
    else {
      Message.error(`error while approved bank details.`)
    }
    yield put(approveBankDetailsSuccess(data))
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* bankApprovalSagaList() {
  yield takeEvery(types.GET_BANK_DETAILS_LIST, BankListSaga);
  yield takeEvery(types.APPROVE_BANK_DETAILS, approvebankDetailsSaga);
}


export default function* rootSaga() {
  yield all([fork(bankApprovalSagaList)])
}