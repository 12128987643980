import React from "react";
import _Get from "lodash/get";

class ErrorBoundComponent extends React.Component {
	constructor(cArgs) {
		super(cArgs);
		this.state = {
			__hasError__: false,
			component: null
		};

		(this).__setState = this.setState;
		

		this.setState = (...args) => {
			try {
				if (!(this).didUnmount) {
					(this).__setState(...args);
				}
			} catch (e) {
				console.error(e);
			}
		};
	}

	componentWillUnmount() {
		(this).didUnmount = true;
		this.mounted = false;
	}

	componentDidCatch(err, info) {
		console.error(err);
		if (!this.state.__hasError__) {
			this.setState({ __hasError__: true });
		}
	}

	_errorComponent = () => (
		(this.props).errorHandlerComponent ? (
			this.props.errorHandlerComponent
		) : (
				<div style={{width: '100%'}}>
					<p style={{textAlign: "center"}}>
						Oops! Something went wrong!
      		</p>
				</div>
			)
	);

	_render = () => null;

	render() {
		if (this.state.__hasError__) {
			return this._errorComponent();
		}
		try {
			return this._render()
		} catch (e) {
			console.error(e);
			return this._errorComponent();
		}
	}
}

export default ErrorBoundComponent