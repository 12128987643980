import * as types from '../../constants/ActionTypes';
import _ from "lodash";

const initialState = {
  hashtagList: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_HASHTAGS:
      return {
        ...state,
        hashtagList: state.hashtagList
      }
    case types.GET_HASHTAGS_SUCCESS:
      return {
        ...state,
        hashtagList: action.hashtagList
      }
    default:
      return state
  }
}