import * as types from '../../constants/ActionTypes';

export const getCreatorListByFilter = (pageNumber, filters) => {
  return {
    type: types.GET_CREATOR_LIST_BY_FILTER,
    pageNumber,
    filters
  }
}

export const getCreatorListByFilterSuccess = ({ data }) => {
  return {
    type: types.GET_CREATOR_LIST_BY_FILTER_SUCCESS,
    creatorList: data
  }
}
export const createCreator = ({ data }) => {
  return {
    type: types.CREATE_CREATOR,
    creatorCreate: data
  }
}
export const createCreatorSuccess = ({ data }) => {
  return {
    type: types.CREATE_CREATOR_SUCCESS,
    creatorCreate: data
  }
}
export const updateCreator = ({ data }) => {
  return {
    type: types.UPDATE_CREATOR,
    creatorUpdate: data
  }
}
export const updateCreatorSuccess = ({ data }) => {
  return {
    type: types.UPDATE_CREATOR_SUCCESS,
    creatorUpdate: data
  }
}
export const blockCreatorSuccess = ({ data }) => {
  return {
    type: types.BLOCK_CREATOR_SUCCESS,
    creatorUserId: data
  }
}
export const blockCreator = ({ data, action, userId }) => {
  return {
    type: types.BLOCK_CREATOR,
    creatorUserId: data,
    modifiedAction: action,
    userId: userId
  }
}
export const resetState = () => {
  return {
    type: types.RESET_CREATOR_STATE
  }
}
