import * as types from '../../constants/ActionTypes';
import _ from "lodash";

const initialState = {
  locationList: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_LOCATIONS:
      return {
        ...state,
        locationList: state.locationList
      }
    case types.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationList: action.locationList
      }
    default:
      return state
  }
}