import * as types from '../../constants/ActionTypes';

export const getHashtags = (hashtagSearch, filterHidden) => {
  return {
    type: types.GET_HASHTAGS,
    hashtagSearch,
    filterHidden
  }
}

export const getHashtagsSuccess = ({ data }) => {
  return {
    type: types.GET_HASHTAGS_SUCCESS,
    hashtagList: data
  }
}