import React from "react";
import ReactDOM, { hydrate as Hydrate } from "react-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import NextApp from './NextApp';
import Loadable from "react-loadable";
// Add this import:
import { AppContainer } from 'react-hot-loader';
import "react-image-lightbox/style.css";

// Wrap the rendering in a function:
const render = Component => {
  if(window && window.location.host.split(".")[2] === "me"){
    document.cookie = "region=DH_MENA"
  }else{
    document.cookie = "region=DH_APP"
  }
  
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <I18nextProvider i18n={i18n}>
        <NextApp />
      </I18nextProvider>
    </AppContainer>,
    document.getElementById('root')
  );
};

Loadable.preloadReady().then(() => (
  Hydrate(
    (
      <AppContainer>
        <I18nextProvider i18n={i18n}>
          <NextApp />
        </I18nextProvider>
      </AppContainer>
    ),
    document.getElementById("root")
  )
)).catch(e => {
  console.error(e, "error")
});

// Do this once
// RegisterWorker();
//registerServiceWorker();
//unregister()

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
