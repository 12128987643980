import * as types from '../../constants/ActionTypes';
import _ from "lodash";

const initialState = {
  postList: [],
  featuredPostsLoading: false,
  campaignsListLoading: false,
  featuredPostList:[],
  campaignsList: [],
  campaignCreated: "",
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPSERT_FEATURED_POSTS:{
      return{
        ...state,
        loading: true
      }
    }
    case types.UPSERT_FEATURED_POSTS_SUCCESS:{
      return{
        ...state,
        loading: false,
        featuredPosts: action.featuredPosts
      }
    }
    case types.CREATE_CAMPAIGN:
      return {
        ...state,
        loading: true,
      }
      case types.CREATE_CAMPAIGN_SUCCESS:
        return {
          ...state,
          loading: false,
          campaignCreated: action.campaignCreated,
        }
    case types.GET_FEATURED_POST:
      return {
        ...state,
        featuredPostsLoading: true,
        // postList: state.postList
      }

    case types.GET_FEATURED_POST_SUCCESS:
      // little hack to make existing postCard work with featuredpost data. So wrapped the response in postDetails property. 
      // TODO: Add the property in backend
      let posts = []
      for (let p of action.featuredPostList){
          let paddedPost = {
            postDetails: p
          }
          posts.push(paddedPost)
        }
      
      return {
        ...state,
        featuredPostsLoading: false,
        featuredPostList: posts
        //nextPageUrl: action.postList.nextPageUrl
      }

    case types.GET_CAMPAIGNS:
      return {
        ...state,
        // loading: true,
        campaignsListLoading: true,
        campaignsList: state.campaignsList
      }

    case types.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        // loading: false,
        campaignsListLoading: false,
        campaignsList: action.campaignsList
        //nextPageUrl: action.postList.nextPageUrl
      }

    case types.GET_POST_LIST_BY_FILTER:
      return {
        ...state,
        loading: true,
        postList: state.postList
      }
    case types.GET_POST_LIST_BY_FILTER_SUCCESS:
      let newPostList = state.postList;
      for (let post of action.postList.row) {
        if (_.filter(state.postList, ({ postDetails }) => postDetails.postUID === post.postDetails.postUID).length == 0) {
          newPostList.push(post);
        }
      }
      return {
        ...state,
        loading: false,
        postList: newPostList,
        nextPageUrl: action.postList.nextPageUrl
      }
    case types.GET_OGC_POST_LIST_BY_FILTER:
      return {
        ...state,
        loading: true,
        postList: state.postList
      }
    case types.GET_OGC_POST_LIST_BY_FILTER_SUCCESS:
      newPostList = state.postList;
      for (let post of action.postList.row) {
        if (_.filter(state.postList, ({ postDetails }) => postDetails.id === post.postDetails.id).length == 0) {
          newPostList.push(post);
        }
      }
      return {
        ...state,
        loading: false,
        postList: newPostList,
        nextPageUrl: action.postList.nextPageUrl
      }
    case types.GET_POST_BY_ID:
      return {
        ...state,
        loading: true
      }
    case types.GET_POST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        postById: action.postById
      }
    case types.GET_OGC_POST_BY_ID:
      return {
        ...state,
        loading: true
      }
    case types.GET_OGC_POST_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        postById: action.postById
      }
    case types.UPSERT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        upsertedPost: action.post
      }
    case types.RESET_POST_STATE:
      return {
        ...state,
        postById: {},
        postList: [],
        upsertedPost: {}
      }
    default:
      return state
  }
}


