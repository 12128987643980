import * as types from '../../constants/ActionTypes';

const initialState = {
  creatorList: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CREATOR_LIST_BY_FILTER:
      return {
        ...state,
        loading:true,
        creatorList: state.creatorList
      }
    case types.GET_CREATOR_LIST_BY_FILTER_SUCCESS:
      return {
        ...state,
        creatorList: action.creatorList,
        loading:false,
        nextPageUrl: action.creatorList.nextPageUrl
      }
      case types.CREATE_CREATOR:
      return{
          ...state,
          loading:true,
          creatorCreate:state.creatorCreate
        }
    case types.CREATE_CREATOR_SUCCESS:
      return{
         ...state,
         loading:false,
          creatorCreate:action.creatorCreate
        }
    case types.UPDATE_CREATOR:
      return{
          ...state,
          loading:true,
          creatorUpdate:state.creatorUpdate
        }
    case types.UPDATE_CREATOR_SUCCESS:
      return{
         ...state,
         loading:false,
          creatorUpdate:action.creatorUpdate
        }
    case types.BLOCK_CREATOR:
      return{
          ...state,
          loading:true,
          creatorUserId:action.creatorUserId,
          modifiedAction:action.action
            }
    case types.BLOCK_CREATOR_SUCCESS:
      return{
          ...state,
          loading:false,
          creatorUserId:action.creatorUserId
            }    
    case types.RESET_POST_STATE:
      return {
        ...state,
        creatorList:[]
      }
    default:
      return state
  }
}
