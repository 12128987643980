import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";


import {
  getDhTagsSuccess
} from "../actions/dhTags.action";

const getDhTagsAxios = async () => {

  try{
    let data = await Axios.get(`/api/dhtags`, {
        userId : 12
    });
    return data
  }catch(error){
    throw error;
  }
}


function* fetchDhTagsListRequest(params) {
  try {
    const data = yield call(getDhTagsAxios);
    yield put(getDhTagsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* fetchDhTagsList() {
  yield takeEvery(types.GET_DHTAGS, fetchDhTagsListRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchDhTagsList)])
}