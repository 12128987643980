import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  getCommCardConfigDataSuccess,
  getConfigDataSuccess,
  getEditorApiKeySuccess
} from "../actions/config.action"

import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";
import QueryString from "query-string";

const fetchConfigDataOnDemand = async () => {
  try{
    let data = await Axios.get(`/api/config`);
    return data;
  }catch(error){
    throw error;
  }
}

const fetchCommConfigOnDemand = async (params) => {
  try {
    let url
    if(params.dhUserId){
      let query = {};
      query["dhUserId"] = params.dhUserId;
      if(params.paymentType){
        query['paymentType'] = params.paymentType
      }
      url=`/api/commConfig?${QueryString.stringify(query)}`
    }else{
      url='/api/commConfig'
    }
    let data = await Axios.get(url)
    return data;
  } catch (error) {
    throw error;
  }
}

const fetchEditorApiKeyOnDemand = async () => {
  try{
    let data = await Axios.get(`/api/getEditorApiKey`);
    return data;
  }catch(error){
    throw error;
  }
}

function* fetchConfigDatarequest(){
  try{
    const data = yield call(fetchConfigDataOnDemand);
    yield put(getConfigDataSuccess(data));
  }catch(error){
    yield put(fetchError(error));
  }
}

function* fetchCommConfigRequest(params) {
  try {
    const data = yield call(fetchCommConfigOnDemand, params);
    yield put(getCommCardConfigDataSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchEditorApiKeyDatarequest(){
  try{
    const data = yield call(fetchEditorApiKeyOnDemand);
    yield put(getEditorApiKeySuccess(data));
  }catch(error){
    yield put(fetchError(error));
  }
}

export function* fetchConfigData() {
  yield takeEvery(types.GET_CONFIG_DATA, fetchConfigDatarequest);
}

export function* fetchCommConfig() {
  yield takeEvery(types.GET_COMM_CONFIG_DATA , fetchCommConfigRequest )
}

export function* fetchEditorApiKey(){
  yield takeEvery(types.GET_EDITOR_API_KEY, fetchEditorApiKeyDatarequest);
}


export default function* rootSaga() {
  yield all([fork(fetchConfigData), fork(fetchCommConfig), fork(fetchEditorApiKey)])
}