import * as types from '../../constants/ActionTypes';

const initialState = {
  currentUser: null,
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: state.currentUser,
        loading: true
      }
    case types.SET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser
      }
    case types.GET_CURRENT_USER:
      return {
        ...state,
        loading: true,
        currentUser: state.currentUser
      }
    case types.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser
      }
    // case types.LOGOUT_USER:
    //   return {
    //     ...state,
    //   }
    // case types.LOGOUT_USER_SUCCESS:
    //   return {
    //     ...state,
    //   }
    default:
      return state
  }
}


