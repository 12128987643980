import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import _ from "lodash";
import { message } from 'antd';
import {
  getOGCPostListByFilterSuccess,
  getPostListByFilterSuccess,
  getPostByIDSuccess,
  upsertPostSuccess,
  getOGCPostByIDSuccess,
  getFeaturedPostSuccess,
  getCampaignsSuccess,
  createCampaignSuccess,
  upsertFeaturedPostSuccess
} from "../actions/post.action";
import {
  getLocations,
  getLanguages,
  getDhTags
} from "../actions";
import {
  notification as Notification
} from "antd"
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";


const upsertPostAxios = async (params) => {
  const {
    post
  } = params
  try {
    let data = await Axios.post(
      `/api/post/upsert/update`,
      {
        post: post,
        userId: params.userId
      }
    );
    return data;
  } catch (error) {
    throw error
  }
}


const getFeaturedPost = async (params) => {
  try {
    let data = await Axios.post(`/api/post/getFeaturedPosts`);
    return data;
  } catch (error) {

    throw error
  }
}

const getCampaigns = async (params) => {
  try {
    let data = await Axios.get(`/api/campaign/list`);
    return data;
  } catch (error) {

    throw error
  }
}

const createCampaign = async (params) => {
  try {
    let data = await Axios.post(`/api/campaign/save`, params);
    if(data.status === 200) {
      message.success("Campaign successfully Created/Updated!");
    }
    return data;
  } catch (error) {

    throw error
  }
}

const getPostByFilter = async (params) => {
  try {
    let filters = params.filters;
    if (filters && filters.state == 'ALL') {
      delete filters.state;
    }
    let isArchived = filters.archived ? filters.archived : false
    delete filters.archived
    let data = await Axios.post(`/api/post/getPostByFilter?archived=${isArchived}`, {
      filters: filters,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      userId: params.userId
    });
    return data;
  } catch (error) {

    throw error
  }
}

const getOGCPostByFilter = async (params) => {
  try {
    let filters = params.filters;
    if (filters && filters.state == 'ALL') {
      delete filters.state;
    }
    let data = await Axios.post(`/api/post/getOGCPostByFilter`, {
      filters: filters,
      count: params.count,
      pageValue: params.pageValue,
      offsetId: params.offsetId,
      userId: params.userId
    });
    return data;
  } catch (error) {

    throw error
  }
}

const getPostById = async (params) => {
  const { postId, archived } = params
  try {
    let data = await Axios.post(`/api/post/${postId}?archived=${archived}`, {
      userId: params.userId
    });
    return data;
  } catch (error) {
    throw error
  }
}

const getOGCPostById = async (params) => {
  const { postId } = params
  try {
    let data = await Axios.post(`/api/post/getOGCPostById/${postId}`, {
      userId: params.userId
    });
    return data;
  } catch (error) {
    throw error
  }
}

const upsertFeaturedPost = async (params) => {
  const { postIds, userId } = params 
  try {
    let data = await Axios.post(`/api/post/upsertFeaturedPost`, {
      postIds,
      userId
    });
    return data;
  } catch (error) {
    
  }
}

function* upsertPostRequest(params) {
  try {
    const data = yield call(upsertPostAxios, params);
    if (_.get(data, "data.data.success")) {
      yield put(upsertPostSuccess({ post: data.data.data.post }));
    } else {
      Notification.error({
        message: "Something went wrong !!"
      })
      throw new Error("Something went wrong !!")
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchPostByIdRequest(params) {
  try {
    const data = yield call(getPostById, params);
    yield put(getPostByIDSuccess({ postById: data }));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchOGCPostByIdRequest(params) {
  try {
    const {data} = yield call(getOGCPostById, params);
    yield put(getOGCPostByIDSuccess({ postById: data }));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchPostListRequest(params) {
  try {
    const data = yield call(getPostByFilter, params);
    yield put(getPostListByFilterSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchFeaturedPostRequest(params) {
  try {
    const data = yield call(getFeaturedPost);
    yield put(getFeaturedPostSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchCampaignsRequest(params) {
  try {
    const data = yield call(getCampaigns);
    yield put(getCampaignsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createCampaignRequest(params) {
  try {
    const data = yield call(createCampaign, params);
    yield put(createCampaignSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


function* fetchOGCPostListRequest(params) {
  try {
    const data = yield call(getOGCPostByFilter, params);
    yield put(getOGCPostListByFilterSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchPostDependenciesRequest(params) {
  try {
    yield all([
      put(getLocations()),
      put(getLanguages()),
      put(getDhTags())
    ])
  } catch (error) {
    throw error
  }
}

function* upsertFeaturedPostRequest(params){
  try {
    const data = yield call( upsertFeaturedPost, params);
    yield put( upsertFeaturedPostSuccess(data))
  } catch (error) {
    yield put(fetchError(error))
  }
}


export function* fetchPostList() {
  yield takeEvery(types.GET_POST_LIST_BY_FILTER, fetchPostListRequest);
  yield takeEvery(types.GET_OGC_POST_LIST_BY_FILTER, fetchOGCPostListRequest);
  yield takeEvery(types.GET_POST_BY_ID, fetchPostByIdRequest);
  yield takeEvery(types.GET_OGC_POST_BY_ID, fetchOGCPostByIdRequest);
  yield takeEvery(types.UPSERT_POST, upsertPostRequest);
  yield takeEvery(types.GET_POST_DEPENDENCIES, fetchPostDependenciesRequest);
  yield takeEvery(types.GET_FEATURED_POST, fetchFeaturedPostRequest);
  yield takeEvery(types.GET_CAMPAIGNS, fetchCampaignsRequest);
  yield takeEvery(types.CREATE_CAMPAIGN, createCampaignRequest);
  yield takeEvery(types.UPSERT_FEATURED_POSTS, upsertFeaturedPostRequest);
}


export default function* rootSaga() {
  yield all([fork(fetchPostList)])
}