import * as types from '../../constants/ActionTypes';

export const getProfileDetails = ( userId ) => {
  
  return {
    type: types.GET_PROFILE_DETAILS,
    userId
  }
}

export const getProfileDetailsSuccess = ({ data }) => {
  
  return {
    type: types.GET_PROFILE_DETAILS_SUCCESS,
    profileDetails: data
  }
}

export const getPaymentList = ( user ) => {
  
  return {
    type: types.GET_PAYMENTS_LIST,
    user
  }
}

export const getPaymentListSuccess = (payments) => {
  
  return {
    type: types.GET_PAYMENTS_LIST_SUCCESS,
    paymentsList: payments
  }
}

export const invoiceAccepted = ( invoiceNumber, user) => {
  
  return {
    type: types.INVOICE_ACCEPTED,
    user,
    invoiceNumber
  }
}

export const invoiceAcceptedSuccess = (invoiceAcceptance) => {
  
  return {
    type: types.INVOICE_ACCEPTED_SUCCESS,
    invoiceAccepted: invoiceAcceptance
  }
}
