import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { Spin } from "antd";
import _Get from "lodash/get";
import MainApp from "./MainApp";
import { getConfigData } from "../../appRedux/actions/config.action";
import { getCurrentUser } from "../../appRedux/actions/currenUser.action";
import { setMobileView, setRegion } from "../../appRedux/actions/common.action";
import i18n from "../../i18n";
import ErrorBoundComponent from "../../components/ErrorBoundComponent";
import CircularProgress from "../../components/CircularProgress";
import { logoutUser } from "../../clientServices/auth"
import TagManager from 'react-gtm-module';
import FreshChat from "react-freshchat";

class App extends ErrorBoundComponent {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const cookieArray = document.cookie ? document.cookie.split("; ") : [];
      const languageCookie = cookieArray.filter(e => e.includes("dh-creator-language"));
      const nextScreenCookie = cookieArray.filter(e => e.includes("nextScreen"));
      const regionCookie = cookieArray.filter(e => e.includes("region"));
      const region = regionCookie.length ? regionCookie[0].replace("region=", "").trim() : "DH_APP";
      const nextScreen = nextScreenCookie.length ? nextScreenCookie[0].replace("nextScreen=", "").trim() : "login";
      const language = languageCookie.length ? languageCookie[0].replace("dh-creator-language=", "").trim() : null;
      if(region){
        this.props.setRegion( region )
      }
      if (language) {
        i18n.changeLanguage(language);
      }
      if (nextScreen) {
        this.setState({
          nextScreen
        })
        // this.props.setNextScreen( nextScreen )
      }
      let isMobile = this.props.common ? this.props.common.isMobile ? this.props.common.isMobile : undefined : undefined;

      if (isMobile == undefined) {
        window.innerWidth < 500 ? this.props.setMobileView(true) : this.props.setMobileView(false);
      }

      // Google Tag Manager related code
      const productionHost = "dhcreator.dailyhunt.in";
      //const devHost = "dhcreator-dev.dailyhunt.in";

      // Only initiate tracking on production URL
      if (window.location.hostname.toLowerCase().search(productionHost) >= 0) {
        const tagManagerArgs = {
          gtmId: "GTM-N8GXQM5"
        };
        TagManager.initialize(tagManagerArgs)
      }

      // if (window.location.hostname.toLowerCase().search(devHost) >= 0) {
      //   const tagManagerArgs = {
      //     gtmId: "GTM-N8GXQM5"
      //   };
      //   TagManager.initialize(tagManagerArgs)
      // }

    }
  }

  componentWillMount() {
    this.props.getConfigData();
    this.props.getCurrentUser();
  }



  componentDidUpdate = () => {
    if (typeof window !== undefined) {
      const cookieArray = document.cookie ? document.cookie.split("; ") : [];
      const nextScreenCookie = cookieArray.filter(e => e.includes("nextScreen"));
      const userId = cookieArray.filter(e => e.includes("dh-auth-user"));
      const nextScreen = nextScreenCookie.length ? nextScreenCookie[0].replace("nextScreen=", "").trim() : "login";
      if (nextScreen !== this.state.nextScreen)
        this.setState({
          nextScreen
        })
    }
  }

  _logout = async () => {
    await logoutUser()
    this.props.getCurrentUser()
  }


  render() {
    const { match, resolvedCurrentUser, configData, loading } = this.props;
    if (configData && resolvedCurrentUser && !loading) {
      return (
        <Route
          path={`${match.url}`}
          render={() =>
            <>
              <MainApp
                logout={this._logout}
                nextScreen={this.state.nextScreen}
                currentUser={resolvedCurrentUser.data}
                configData={configData}
                {...this.props}
              />
              {/* Fresh chat not supported now. */}
              {/* {window.innerWidth < 500 ?
                ""
                :
                <FreshChat
                  token={configData.freshchatToken}
                  config={this.props.isMobile ?
                    {
                      headerProperty:
                      {
                        direction: 'ltr'
                      }
                    }
                    :
                    {}
                  } />
              } */}
            </>
          }
        />
      )
    }
    return (
      <CircularProgress />
    )
  }
}

const mapStateToProps = (store) => {
  const loading = _Get(store, "currentUser.loading")
  const resolvedCurrentUser = _Get(store, "currentUser.currentUser");
  const configData = _Get(store, "config.config");
  const common = _Get(store, "common");
  const isMobile = _Get(store, "common.isMobile");
  return { loading, resolvedCurrentUser, configData, common, isMobile }
};

export default connect(mapStateToProps, { getCurrentUser, getConfigData, setMobileView, setRegion })(App);
