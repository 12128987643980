import * as types from '../../constants/ActionTypes';

export const getBankDetailsList = () => {
  return {
    type: types.GET_BANK_DETAILS_LIST
  }
}

export const getBankDetailsListSuccess = (data) => {
  return {
    type: types.GET_BANK_DETAILS_LIST_SUCCESS,
    data
  }
}

export const approveBankDetails = (payload, userId) => {
  return {
    type: types.APPROVE_BANK_DETAILS,
    payload,
    userId
  }
}

export const approveBankDetailsSuccess = (data) => {
  return {
    type: types.APPROVE_BANK_DETAILS_SUCCESS,
    data
  }
}