import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import * as types from '../../constants/ActionTypes';
import { fetchError } from "../actions/common.action";
import  { notification } from 'antd';

import {
  getProfileDetailsSuccess,
  getPaymentListSuccess,
  invoiceAcceptedSuccess,
} from "../actions/profile.action";

const getProfileDetailsData = async ( params ) => {
  const { userId } = params
  try{
    let data = await Axios.get(`/api/getProfileDetails?userId=${userId}`);
    ;
    return data
  }catch(error){
    throw error
  }
}

const getPaymentsList = async ( params ) => {
  const { user } = params
  try {
    let data = await Axios.post('/api/getPaymentList', user);
    return data;
  }catch(error){
    throw error
  }
}

const invoiceAccepted = async ( params ) => {
  try {
    let data = await Axios.post('/api/invoiceAccepted', params);
    if(data.data.status === 200) {
      notification.success({
        message: data.data.data
      })
    }
    return data;
  }catch(error){
    throw error
  }
}

function* getPaymentsListGenerator (params) {
  try {
    const data = yield call(getPaymentsList, params);
    yield put(getPaymentListSuccess(data));
  } catch (error){
    yield put(fetchError(error));
  }
}


function* invoiceAcceptedGenerator (params) {
  try {
    const data = yield call(invoiceAccepted, params);
    yield put(invoiceAcceptedSuccess(data));
  } catch (error){
    yield put(fetchError(error));
  }
}


function* fetchGetProfileDetailsRequest( params ) {
  try {
    const data = yield call(getProfileDetailsData, params);
    yield put(getProfileDetailsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}


export function* fetchProfileDetails() {
  yield takeEvery(types.GET_PROFILE_DETAILS, fetchGetProfileDetailsRequest);
  yield takeEvery(types.GET_PAYMENTS_LIST, getPaymentsListGenerator);
  yield takeEvery(types.INVOICE_ACCEPTED, invoiceAcceptedGenerator);
}


export default function* rootSaga() {
  yield all([fork(fetchProfileDetails)])
}