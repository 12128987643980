import * as types from '../../constants/ActionTypes';

export const uploadImage = ( file ) => {
  return {
    type: types.UPLOAD_IMAGE,
    file
  }
}

export const uploadImageSuccess = ({ data }) => {
  return {
    type: types.UPLOAD_IMAGE,
    imageUrl: data
  }
}
