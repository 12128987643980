import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import Axios from "axios";

import {
  getAccountListByFilter,
  getAccountListByFilterSuccess,
  upsertAccountSuccess
} from "../actions/account.action";
import {notification as Notification,message} from "antd";
import _Get from "lodash/get";
import * as types from '../../constants/ActionTypes';
import {fetchError} from "../actions/common.action";

const getAccountByFilter = async (params) => {
  try{
    let data = await Axios.post(`/api/account/getAccountByFilter`,{
      filters: params.filters
    });
    return data;
  }catch(error){
    throw error;
  }
}
const upsertAccount = async (params) =>{
  try{
    let data = await Axios.post(`/api/account/upsert`,{accountData:params.accountUpsert});
    if (data.status === 200) {
      Notification.success({
        message: "Success"
      })
    } else {
      Notification.error({
        message: _Get(data, "data.message") || "Something went wrong !!"
      })
    }
    return data;
  }catch(error){
    throw error;
  }
}
function* upsertAccountRequest(params){
  
  try{
    const data = yield call(upsertAccount,params)
    yield call(fetchAccountListRequest, params);
    yield put(upsertAccountSuccess(data))
    }catch(error){
    yield put(fetchError(error));
  }
}

function* fetchAccountListRequest(params){
  try{
    const data = yield call(getAccountByFilter,params)
    
    yield put(getAccountListByFilterSuccess(data))
  }catch(error){
    yield put(fetchError(error));
  }
}

export function* fetchAccountList() {
  yield takeEvery(types.GET_ACCOUNT_LIST_BY_FILTER, fetchAccountListRequest);
  // yield takeEvery(types.UPSERT_ACCOUNT, upsertAccountRequest);
}

export function* upsertAccountGeneratorMap() {
  yield takeEvery(types.UPSERT_ACCOUNT, upsertAccountRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchAccountList),fork(upsertAccountGeneratorMap)])
}
