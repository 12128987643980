import Axios from "axios"
import Uuid from "uuid/v4"
import * as exif from "@ginpei/exif-orientation"


import {
	BCDN_IMAGE_MACRO,
	REP_IMAGE_MACRO
} from "../constants/uiConstants"

export const uploadImageService = async (file, setFileList = null, SuccessFunc, ErrFunc, initFunc, curryCancelFunc, setProgressStatusFunc, getMeta, addCaption) => {
	try {
		let formData = new FormData();
		let url
		let format = file.type ? file.type : "png"
		format = format.replace("image/", "")
		formData.append("imageFile", file);
		const CancelToken = Axios.CancelToken;
		!!initFunc && initFunc();
		const response = await Axios({
			method: "post",
			url: "/api/image?isFile=true",
			data: formData,
			cancelToken: new CancelToken(cancelFunc => {
				!!curryCancelFunc && curryCancelFunc(cancelFunc);
			}),
			onUploadProgress: (progressEvent) => {
				const currentStatus = Math.round(progressEvent.loaded / progressEvent.total * 100);
				!!setProgressStatusFunc && setProgressStatusFunc(currentStatus)
			}
		})
		if (!!setFileList) {

			try {
				setFileList(
					{
						uid: Uuid(),
						name: file.name ? file.name : "img.jpg",
						status: response && response.status === 200 ? "done" : "error",
						response: response && response.status === 200 ? "{\"status\": \"success\"}" : "{\"status\": \"failed\"}", // custom message to show
						url: response && response.data && response.data.fileName ? response.data.fileName : "",
						id: response && response.data && response.data.fileName ? response.data.fileName : "",
						height: response.data.height,
						width: response.data.width,
						format: format,
						linkProps: "{\"className\": \"normal_font_color\"}"
					},
					true
				)
			} catch (error) {
				console.error(error)
			}
		} else {

			if (response && response.data && response.data.fileName) {
				!!SuccessFunc && SuccessFunc(response.data.fileName, response.status, response.data.height, response.data.width)
			}
		}

		url = response.data && response.data.fileName ? response.data.fileName : ""
		!!getMeta && getMeta({ height: response.data.height, width: response.data.width, format, url: url.replace(BCDN_IMAGE_MACRO, "") })
		return url
	} catch (e) {
		return
	}
}

export const uploadConfidentialImageService = async (file, setFileList = null, SuccessFunc, ErrFunc, initFunc, curryCancelFunc, setProgressStatusFunc, getMeta, addCaption, userId) => {
	try {
		let formData = new FormData();
		let url
		let format = file.type ? file.type : "png"
		format = format.replace("image/", "")
		formData.append("file", file);
		const CancelToken = Axios.CancelToken;
		!!initFunc && initFunc();
		const response = await Axios({
			method: "post",
			url: `/api/confidential/image?isFile=true&userId=${userId}`,
			data: formData,
			cancelToken: new CancelToken(cancelFunc => {
				!!curryCancelFunc && curryCancelFunc(cancelFunc);
			}),
			onUploadProgress: (progressEvent) => {
				const currentStatus = Math.round(progressEvent.loaded / progressEvent.total * 100);
				!!setProgressStatusFunc && setProgressStatusFunc(currentStatus)
			}
		})
		if (!!setFileList) {

			try {
				setFileList(
					{
						uid: Uuid(),
						name: file.name ? file.name : "img.jpg",
						status: response && response.status === 200 ? "done" : "error",
						response: response && response.status === 200 ? "{\"status\": \"success\"}" : "{\"status\": \"failed\"}", // custom message to show
						url: response && response.data && response.data ? response.data : "",
						id: response && response.data && response.data ? response.data : "",
						format: format,
						linkProps: "{\"className\": \"normal_font_color\"}"
					},
					true
				)
			} catch (error) {
				console.error(error)
			}
		} else {

			if (response && response.data && response.data) {
				!!SuccessFunc && SuccessFunc(response.data, response.status, response.data.height, response.data.width)
			}
		}

		url = response.data && response.data ? response.data : ""
		return url
	} catch (e) {
		return
	}
}

export const getBufferFromImageUrl = async (imageUrl, userId) => {
	return await Axios.post('/api/getBufferFromImageUrl', { imageUrl, userId })
}

export const addServingUrlToConfidentialImage = (url, filePath) => {
	if( filePath && filePath.data ){
		return url + filePath.data
	}else{
		return ""
	}
}

export const removeMacro = (macroUrl) => {
	let url
	if (macroUrl.includes(REP_IMAGE_MACRO)) {
		url = macroUrl.replace(REP_IMAGE_MACRO, "")
	} else {
		url = macroUrl.includes(BCDN_IMAGE_MACRO) ? macroUrl.replace(BCDN_IMAGE_MACRO, "") : ""
	}
	return url
}

export const replaceMacroWithImageServingUrl = (macroUrl, imageServiceUrl, bbcrop, ratio, resize, resizeRation) => {
	if (bbcrop) {
		const url = macroUrl.includes(BCDN_IMAGE_MACRO) ? macroUrl.replace(BCDN_IMAGE_MACRO, `${imageServiceUrl}/cmd/bbcrop/${ratio}/`) : ""
		return url
	} else if (resize) {
		const url = macroUrl.includes(BCDN_IMAGE_MACRO) ? macroUrl.replace(BCDN_IMAGE_MACRO, `${imageServiceUrl}/${resizeRation}`) : ""
		return url
	} else {
		const url = macroUrl.includes(BCDN_IMAGE_MACRO) ? macroUrl.replace(BCDN_IMAGE_MACRO, imageServiceUrl) : ""
		return url
	}
}

export const appendImageServingUrl = (imageId, imageServiceUrl, bbcrop, ratio) => {
	if (bbcrop) {
		return `${imageServiceUrl}/cmd/bbcrop/${ratio}/${imageId}`
	} else {
		return `${imageServiceUrl}/${imageId}`
	}
}

export const removeImageServingUrl = (url, imageServiceUrl) => {
	if (url.includes('bbcrop')) {
		const cleanedImageid = url.replace(imageServiceUrl, "").replace("/cmd/bbcrop", "").replace("/600x337_60/", "").replace("/300x337_60/", "")
		return cleanedImageid
	} else {
		return url.includes(imageServiceUrl) ? url.replace(imageServiceUrl, "") : url
	}
}

export const checkOrientation = async (file, getRotatedImageFunc, checkOrientation) => {
	try {

		const image = new FormData()
		image.append('imageFile', file)

		let rotation
		if (checkOrientation) {
			const result = await Axios.post('/api/image-orientation', image)
			rotation = result.data.orientation ? result.data.orientation.rotation : 0
		} else {
			rotation = 0
		}
		// const loading = Message.loading( "Action in progress..." )
		let reader = new FileReader();
		let url;
		reader.onload = () => {
			url = reader.result
			// this._setImage( url, rotation, editor )
			const canvas = document.createElement("canvas");
			let context = canvas.getContext("2d");
			let image = new Image();
			image.src = url;
			image.onload = () => {
				let imgwidth = image.naturalWidth;
				let imgheight = image.naturalHeight;
				if (rotation === 0 || rotation === 180) {
					canvas.width = imgwidth;
					canvas.height = imgheight;
				} else {
					canvas.width = imgheight;
					canvas.height = imgwidth;
				}
				context.save();
				context.translate(canvas.width / 2, canvas.height / 2);
				context.rotate(rotation * Math.PI / 180.0);
				context.drawImage(image, -(imgwidth / 2), -(imgheight / 2));
				context.restore();
				const orientedBase64Url = canvas.toDataURL()
				if (file.type.includes("gif")) {
					getRotatedImageFunc(file, orientedBase64Url)
				} else {
					fetch(orientedBase64Url).then(res => res.blob()).then(async blob => {
						const file = new File([blob], "image.png", { type: "image/png" })
						getRotatedImageFunc(file, orientedBase64Url)
					})
				}
			};
		}
		reader.readAsDataURL(file)
	} catch (error) {
		console.error(error)
	}
}

const createImage = base64 =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener("load", () => resolve(image))
		image.addEventListener("error", error => reject(error))
		image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
		image.src = base64
	})

export const CropImage = async (base64file, croppedAreaPixel, getCroppedFile) => {
	// const file = await base64toImageFile( base64file )
	const image = await createImage(base64file)
	const canvas = document.createElement("canvas")
	const ctx = canvas.getContext("2d")
	const safeArea = Math.max(image.width, image.height) * 2
	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea
	canvas.height = safeArea
	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2)
	ctx.translate(-safeArea / 2, -safeArea / 2)
	// draw rotated image and store data.
	ctx.drawImage(
		image,
		safeArea / 2 - image.width * 0.5,
		safeArea / 2 - image.height * 0.5
	)
	const data = ctx.getImageData(0, 0, safeArea, safeArea)
	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = croppedAreaPixel.width
	canvas.height = croppedAreaPixel.height
	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		0 - safeArea / 2 + image.width * 0.5 - croppedAreaPixel.x,
		0 - safeArea / 2 + image.height * 0.5 - croppedAreaPixel.y
	)
	return canvas.toDataURL("image/jpeg")
}