import Axios from "axios";

export const logoutUser = async () => {
  try {
    await Axios.get('/api/auth/logout');
  } catch (error) {
    return error
  }
}

export const login = async (user) => {
  try {
    const response = await Axios.post('/api/auth/login',
      {
        user
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}

export const loginLinkAccount = async (user, queryParams) => {
  try {
    const response = await Axios.post('/api/auth/login/linkOgcAccount',
      {
        user,
        queryParams
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}



export const resetNewUserCookie = async () => {
  try {
    return await Axios.get('/api/auth/resetNewUserCookie');
  } catch (error) {
    return error
  }
}

export const link = async (user, userHeader) => {
  try {
    const response = await Axios.post('/api/auth/link',
      {
        user,
        userHeader
      }
    )
    return response.data
  } catch (error) {
    return error
  }
} 
