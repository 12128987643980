import * as types from '../../constants/ActionTypes';

const initialState = {
  profileDetails:null, 
  paymentsList: null,
  invoiceAcceptedStatus: false,
  loading: false
}

export default (state = initialState, action) => {
  switch(action.type){
    
    case types.GET_PROFILE_DETAILS:
      return {
        ...state,
        profileDetails: null
      }
    case types.GET_PROFILE_DETAILS_SUCCESS:
  
      return {
        ...state,
        profileDetails: action.profileDetails,
      }
    case types.GET_PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        paymentsList: action.paymentsList.data.data ? action.paymentsList.data.data : action.paymentsList.data.message,
        invoiceAcceptedStatus: false
      }
    case types.INVOICE_ACCEPTED_SUCCESS:
      return {
        ...state,
        invoiceAcceptedStatus: action.invoiceAccepted.data.message === "Success" ? true : false
      }
    default:
      return state
  }
}


