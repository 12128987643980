import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { List, Avatar, Row, Col, Button, Radio, Card, Icon, Divider, message, Modal } from 'antd';
import _ from "lodash";
import TRUE_CALLER_ICON from "../../../assets/images/truecaller_icon_small.svg";
import ErrorBoundComponent from '../../../components/ErrorBoundComponent';
import { AccountLinking as SelectAccount } from "../../../clientServices/profile";
import { logoutUser } from "../../../clientServices/auth";
import {
  getCurrentUser
} from "../../../appRedux/actions/currenUser.action";

import "./styles.css"
class AccountLinking extends ErrorBoundComponent {

  constructor(props) {
    super(props)
    this.state = {
      second: 60,
      isTimerOn: true,
      setTimer: true,
      visible: true,
      selectedId: null,
    }
  }

  componentDidMount() {
    this.timer = setInterval(() => this.setState({
      second: this.state.second - 1
    }), 1000)
    this.stopTimer = setTimeout(async () => {
      clearInterval(this.timer);
      await this._accountAction("timedOut")
      this.props.getCurrentUser()
    }, 60000)
  }

  componentWillUnmount() {
    clearTimeout(this.stopTimer)
    clearInterval(this.timer)
  }

  // componentDidUpdate(){
  //   if(!this.state.isTimerOn){
  //     clearTimeout(this.stopTimer)
  //   }
  // }

  _accountAction = async (actionType) => {
    const selectedAccount = this.state.selectedId
    let account = {
      user: {
        userId: this.props.userId
      }
    }
    switch (actionType) {
      case "timedOut": {
        account["isTimedOut"] = true
        break;
      }
      case "done": {
        const notSelectedAccount = _.get(_.filter(_.get(this.props, "accountOptions.dhAccounts"), ({ keyIdentifier }) => {
          return keyIdentifier !== selectedAccount
        }), "0.keyIdentifier")

        account["dhAccount"] = {
          selectedId: selectedAccount,
          defunctId: notSelectedAccount
        }
        break;
      }
      case "skipped":
      default: break;
    }
    // if (selectedAccount & !skipped) {

    // }else if(!skipped && !selectedAccount){
    //   message.error("Please select a Primary Account or Skip")
    //   return;
    // }
    const response = await SelectAccount(account);
    if (response && response.data && response.data.nextScreen) {
      const timedOut = account.isTimedOut;
      message.info({ type: timedOut ? "error" : "info", content: timedOut ? "Oops! Time out. Please try again." : "Done" })
      if (response.data.nextScreen === "login") {
        await logoutUser()
      }
    } else {
      message.error((response && response.error && response.error.message) || "Error Occured while linking")
      await logoutUser()
    }
    this.setState({
      visible: false
    })
    clearTimeout(this.stopTimer)
  }

  _selectConflictMobileNumber = (e) => {
    const keyIdentifier = e.target.value;
    this.setState({
      mobileNumber: keyIdentifier
    })
  }

  _selectedAccount = (e) => {
    const selectedId = e.target.value;
    this.setState({
      selectedId
    })
  }

  _getLinkedAccounts = (linkedAccounts) => {
    return _.map(linkedAccounts, ({ loginType }) => {
      switch (loginType) {
        case "GOOGLE": {
          return (
            <Button
              size="small"
              type="danger"
              icon={"google"}
              shape="circle-outline"
              style={{ color: "white", marginRight: "0.25rem" }}
            />
          )
        }
        case "FACEBOOK": {
          return (
            <Button
              size="small"
              shape="circle-outline"
              style={{ backgroundColor: "blue", color: "white", marginRight: "0.25rem" }}
            >
              <Icon type="facebook" theme="filled" />
            </Button>
          )
        }
        case "MOBILE": {
          return (
            <Button
              size="small"
              shape="circle-outline"
              style={{ backgroundColor: "#1f9ee1", color: "white", marginRight: "0.25rem" }}
            >
              <Icon component={() => (<img src={TRUE_CALLER_ICON} />)} />
            </Button>
          )
        }
      }
    })
  }

  _accountsRenderer = ({ keyIdentifier, profileImage, name, handle, linkedAccounts, creatorType }) => {
      let shouldDisplay = !this.props.iccWithUgc || ( creatorType && creatorType.toLowerCase() === "icc" )
      const { isMobile } = this.props
      const parsedProfileImage = profileImage ? profileImage.replace("#DH_EMB_IMG_REP#", "wxh").replace("#DH_EMB_IMG_EXT#", "jpg") : "";
      return (
        <List.Item style={{display: `${shouldDisplay ? "" : "none"}`}} key={keyIdentifier}>
          <Radio style={{ display: "flex", alignItems: "center" }} value={keyIdentifier}>
            <Row style={{ width: "100%", cursor: "pointer", display: "flex", alignItems: "center" }} >
              <Col span={3}>
                <Avatar size="large" src={parsedProfileImage} icon="user" />
              </Col>
              <Col style={{ marginLeft: "1rem" }} span={18}>
                <div>
                  {name}
                </div>
                <div style={{ display: "flex", justifyContent: `${isMobile ? "center" : ""}`, alignItems: `${isMobile ? "" : "center"}`, width: "100%", flexFlow: `${isMobile ? "column" : "row"}` }}>
                  <div style={{ color: "rgb(135, 135, 135)", margin: "0.25rem 0.25rem 0.25rem 0rem" }}>
                    @{handle}
                  </div>
                  <div>
                    {
                      this._getLinkedAccounts(linkedAccounts)
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Radio>
        </List.Item>
      )
  }

  _confilctMobileNumbersRenderer = ({ keyIdentifier, profileImage, name, handle, linkedAccounts }) => {
    const { isMobile } = this.props
    const parsedProfileImage = profileImage ? profileImage.replace("#DH_EMB_IMG_REP#", "wxh").replace("#DH_EMB_IMG_EXT#", "jpg") : "";
    return (
      <List.Item key={keyIdentifier}>
        <Radio style={{ display: "flex", alignItems: "center" }} value={keyIdentifier}>
          <Row style={{ width: "100%", cursor: "pointer", display: "flex", alignItems: "center" }} >
            <Col span={3}>
              <Avatar size="large" src={parsedProfileImage} icon="user" />
            </Col>
            <Col style={{ marginLeft: "1rem" }} span={18}>
              <div>
                {name}
              </div>
              <div style={{ display: "flex", justifyContent: `${isMobile ? "center" : ""}`, alignItems: `${isMobile ? "" : "center"}`, width: "100%", flexFlow: `${isMobile ? "column" : "row"}` }}>
                <div style={{ color: "rgb(135, 135, 135)", margin: "0.25rem 0.25rem 0.25rem 0rem" }}>
                  @{handle}
                </div>
                <div>
                  {
                    this._getLinkedAccounts(linkedAccounts)
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Radio>
      </List.Item>
    )
  }

  _conflictMobileNumberList = () => {
    const conflictMobileList = _.get(this.props, "accountOptions.conflictAccounts")
    return (
      <Radio.Group onChange={this._selectConflictMobileNumber}>
        <List
          className="accounts-list"
          size="small"
          itemLayout="horizontal"
          dataSource={conflictMobileList}
          renderItem={this._confilctMobileNumbersRenderer}
        // footer={
        //   <div style={{ display:"flex", justifyContent:"flex-end", width:"100%"}}>
        //     <Button onClick={ this._accountAction.bind(this, null ) } style={{textTransform:"inherit"}}  type="link">
        //       Skip
        //     </Button>
        //   </div>
        //   }
        />
      </Radio.Group>
    )
  }

  _accountsList = () => {
    
    const accountsList = _.get(this.props, "accountOptions.dhAccounts")
    if (accountsList) {
      return (
        <Radio.Group onChange={this._selectedAccount}>
          <List
            className="accounts-list"
            size="small"
            itemLayout="horizontal"
            dataSource={accountsList}
            renderItem={this._accountsRenderer}
          // footer={
          //   <div style={{ display:"flex", justifyContent:"flex-end", width:"100%"}}>
          //     <Button onClick={ this._accountAction.bind(this, null ) } style={{textTransform:"inherit"}}  type="link">
          //       Skip
          //     </Button>
          //   </div>
          //   }
          />
        </Radio.Group>
      )
    }
  }

  _content = () => {
    return (
      <>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
            <h1>{this.props.iccWithUgc ? "Your mobile no. is already linked with an existing account." : "Choose a Primary Account"}</h1>
          </div>
          <div style={{ display: "flex", textAlign: "center", color: "rgb(135, 135, 135)", justifyContent: "center" }}>
            <p>{this.props.iccWithUgc ?  "Click on 'Confirm' to merge details or 'Skip' to create a seperate account." : "Only Primary Account's Data will be Retained"}</p>
          </div>
          {
            this._accountsList()
          }
        </div>
        {/* { _.get(this.props, "accountOptions.conflictAccounts") 
          &&
          !_.isEmpty(_.get(this.props, "accountOptions.conflictAccounts"))
          &&
          <div style={{ width:"100%", marginTop:"2rem",display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div style={{ display: "flex",  textAlign:"center", justifyContent: "center" }}>
              <h1>{"Choose a Primary Phone Number"}</h1>
            </div>
            <div style={{ display: "flex", textAlign:"center",  color:"rgb(135, 135, 135)", justifyContent: "center" }}>
            <p>{"Only primary number will be linked to your account"}</p>
            </div>
            {
              this._conflictMobileNumberList()
            }
          </div>
        } */}
        <Divider />
        <Row gutter={8}>
          <Col span={24}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={async () => {
                if (this.state.selectedId) {
                  await this._accountAction("done")
                  this.props.getCurrentUser()
                } else {
                  return message.error("Select a Primary Account or Skip")
                }
              }}
            >
              {`Confirm`}
            </Button>
          </Col>
          <Col span={24}>
            <Button
              size="small"
              onClick={async () => {
                this._accountAction("skipped")
                this.props.getCurrentUser()
              }}
              style={{ width: "100%", textTransform: "inherit" }}
              type="link"
            >
              Skip ( automatically skiping in {this.state.second}s )
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  _onCancel = () => {
    this.setState({
      visible: false
    })
  }

  _render = () => {
    const { isMobile } = this.props
    return (
      <Modal
        visible={this.state.visible}
        width={`${isMobile ? "20rem" : "30rem"}`}
        centered={true}
        onCancel={this._onCancel}
        closable={false}
        maskClosable={false}
        footer={null}
        bodyStyle={{ padding: 0 }}
        title={null}
      >
        <Card >
          {
            this._content()
          }
        </Card>
      </Modal>
    )
  }
}


const mapStateToProps = (store) => {
  let iccWithUgc = false
  let accounts = _.get(store, "common.accounts.dhAccounts")
  let account1 = accounts[0]
  let account2 = accounts[1]
  if (account1.creatorType !== account2.creatorType) {
    iccWithUgc = true
  }
  return {
    accountOptions: _.get(store, "common.accounts"),
    iccWithUgc
  };
}


export default connect(mapStateToProps, { getCurrentUser })(withTranslation()(AccountLinking))