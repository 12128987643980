import React from "react";
import { Layout, Drawer, Button, Menu, Row, Modal } from "antd";
import { withTranslation } from "react-i18next";
import QueryString from "query-string";
import _Get from "lodash/get";
import _Map from "lodash/map";
import LoginDependentRoutes from "../../routes/App/loginDependentRoutes";
import LoginRoutes from "../../routes/Login/loginRoutes";
import {
  setUtmParams, setPostType
} from "../../appRedux/actions/common.action";

import OnBoardingFlowContainer from "../OnboardingFlows/onBoardingModalContainer";

import "../../util/lodash_mixins";
import { connect } from "react-redux";
import i18n from "../../i18n";

import "./mainapp.styles.css"
import OnBoardingLayout from "../../components/OnBoardingLayout/onboardingLayout";

import {
  getProfileDetails
} from "../../appRedux/actions/profile.action";

import supportedLanguages from "../../locales/supportedLanguages";
import { setAppLanguage } from "../../clientServices/appLangage";
import ErrorBoundComponent from "../../components/ErrorBoundComponent";
import "../../util/lodash_mixins";

const { Content } = Layout;

export class MainApp extends ErrorBoundComponent {

  constructor(props) {
    super(props)
    this.state = {
      showOnboardingModal: false,
      showLanguageSelector: false,
    }
  }

  getContainerClass = () => {
    return "gx-container-wrap";
  };

  componentDidMount() {
    const { currentUser } = this.props
    const utmParameter = QueryString.extract(this.props.location.search)
    this.props.setPostType("ICC+UGC")
    if (utmParameter && utmParameter.includes("utm_")) {
      this.props.setUtmParams(utmParameter)
    }

    if (currentUser) {
      if (currentUser.nextScreen === "dashboard") {
        const userId = currentUser.userId
        userId && this.props.getProfileDetails(userId)
      }
    }
    this.setState({
      appLanguage: i18n.language
    })
  }

  _changeAppLanguage = async (language) => {
    i18n.changeLanguage(language)
    this.setState({
      appLanguage: i18n.language,
      showLanguageSelector: false
    })
    const response = await setAppLanguage(language);
  }

  _languageDrawer = () => {
    if (_Get(this.props.common, "isMobile")) {
      return (
        <Modal
          closable={true}
          maskClosable={true}
          onCancel={this._onClose}
          footer={null}
          visible={this.state.showLanguageSelector}
        >
          <div style={{ display: "flex", flexFlow: "column", justifyContent: "space-around" }}>
            <Row className="language-drawer-desc">
              {this.props.t("CHOOSE_LANGUAGE")}
            </Row>
            <Row style={{ flexFlow: "column" }} className="language-options-conatiner">
              {
                _Map(supportedLanguages, ({ label, value }) => {
                  return (
                    <Button shape="round" style={{ margin: 5 }} type={value === this.state.appLanguage ? "primary" : "default"} onClick={this._changeAppLanguage.bind(this, value)}>
                      {
                        label
                      }
                    </Button>
                  )
                })
              }
            </Row>
          </div>
        </Modal>
      )
    }
    return (
      <Drawer
        placement={"top"}
        maskClosable={true}
        closable={false}
        onClose={this._onClose}
        height={"auto"}
        headerStyle={{ display: "none" }}
        bodyStyle={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center"
        }}
        visible={this.state.showLanguageSelector}
      >
        <div style={{ width: "80%" }}>
          <div className="language-drawer-desc">
            {this.props.t("CHOOSE_LANGUAGE")}
          </div>
          <div className="language-options-conatiner">
            {
              _Map(supportedLanguages, ({ label, value }) => {
                return (
                  <Button shape="round" type={value === this.state.appLanguage ? "primary" : "default"} onClick={this._changeAppLanguage.bind(this, value)}>
                    {
                      label
                    }
                  </Button>
                )
              })
            }
          </div>
        </div>
      </Drawer>
    )
  }

  _onClose = () => {
    this._toggleLanguageSelector(false)
  }

  _toggleLanguageSelector = (visibility) => {
    this.setState({
      showLanguageSelector: visibility
    })
  }

  _onBoardingFlow = () => {
    return (
      <OnBoardingFlowContainer
        {...this.props}
        showOnboardingModal={this.state.showOnboardingModal}
        nextScreen={_Get(this.props, "currentUser.nextScreen")}
        toggleOnBoardingModal={this._toggleOnBoardingModal}
      />
    )
  }

  _toggleOnBoardingModal = (visibility) => {
    this.setState({
      showOnboardingModal: visibility
    })
  }

  _togglePostType = (isOGC) => {
    let postType = "ICC+UGC"
    if (isOGC) {
      postType = "OGC"
    }
    this.props.setPostType(postType)
  }

  render() {
    //console.log(this)
    const { currentUser, nextScreen } = this.props;
    if (currentUser || (nextScreen && nextScreen !== "login")) {
      // const onBoardingPending = currentUser.nextScreen !== "post-listing" && currentUser.nextScreen !== "dashboard"
      return (
        <div style={{ width: "100%" }}>
          <LoginDependentRoutes
            appLanguage={this.state.appLanguage}
            postType={this.state.postType}
            toggleLanguageSelector={this._toggleLanguageSelector}
            togglePostType={this._togglePostType}
            {...this.props} />
          {
            this._languageDrawer()
          }
        </div>
      )
    } else {
      return (
        <OnBoardingLayout
          appLanguage={this.state.appLanguage}
          toggleLanguageSelector={this._toggleLanguageSelector}
        >
          <LoginRoutes {...this.props} />
          {
            this._languageDrawer()
          }
        </OnBoardingLayout>
      )
    }
  }
}

const mapStateToProps = (store) => {
  //console.log(store, "MainApp")
  return {
    common: _Get(store, "common")
  }
};
export default connect(mapStateToProps, { getProfileDetails, setPostType, setUtmParams })(withTranslation()(MainApp));
